import React, { Fragment, useEffect, useRef,useState } from "react";
import { Row, Col, Card, Divider, Button, Input, Modal, Tabs, Select, Drawer, message, Checkbox, Form } from "antd";
import { SearchOutlined, ArrowLeftOutlined, CaretDownOutlined, CloseOutlined, SyncOutlined } from "@ant-design/icons";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../lib/printer/xmlPrinter";
import { Scrollbars } from "react-custom-scrollbars";
import DropdownCircle from "../../../assets/images/DropdownCircle.png";
import Backspace from "../../../assets/images/backspac.png";
import SelectIcon from "../../../assets/images/selectIcon.png";
import Close from "../../../assets/images/close-x.svg";
import Tick from "../../../assets/images/tick-small.svg";
import AspectRatio from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";
import "../../style.css";
import "./styles.css";
import "../../globalStyles.css";
import data from "../../../constants/retailPos.json";
import QRCode from "react-qr-code";
import CancelArrow from "../../../assets/images/cancelArrow.svg";
import { useWindowDimensions } from "../../../lib/useViewport";
// OMS Orders Modal Imports //
import POSLogo from "../../../assets/images/cwPosLogo.png";
import MobileIcon from "../../../assets/images/mobileIcon.svg";
import userIcon from "../../../assets/images/salesRep.svg";
import Voucher from "../../../assets/images/voucher.svg";
import DefaultProductImage from "../../../assets/images/no-image.svg";
import _ from "lodash";
import axios from "axios";
import db from "../../../database";
import { useTranslation } from "react-i18next";

// GLOBAL CONSTANTS START
const TabPane = Tabs.TabPane;
const { Option } = Select;
// GLOBAL CONSTANTS END

const PointOfSaleRetailModals = (props) => {
  const {
    cart,
    inputFocused,
    setInputFocused,
    displayOrderHistory,
    setDisplayOrderHistory,
    searchOrderHistory,
    showOrderHistory,
    setOrderHistoryInput,
    orderHistoryDetails,
    showOrderHistoryLine,
    selectedOrderHistoryLine,
    isPrintModeXML,
    syncOrders,
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    selectParkedBill,
    displaySetOrderType,
    setDisplayOrderType,
    posSaleTypes,
    changeOrderType,
    displayOfferProductSelectiton,
    setDisplayOfferProductSelection,
    offerProductsList,
    selectOfferProduct,
    displayBatchSelection,
    setDisplayBatchSelection,
    batchSetAvailable,
    selectProductToCart,
    displayManualQtyWeightInput,
    setDisplayManualQtyWeightInput,
    currentWeightSelectedProduct,
    productWeightModalInput,
    onProductModalChangeWeight,
    addManualWeightToProduct,
    handleWeightManual,
    couponModalVisible,
    closeCouponModal,
    checkCoupon,
    setCouponInput,
    setLoyaltyInputValue,
    loyaltyInputValue,
    couponInput,
    handleCouponInput,
    handleLoyalityInput,
    loyalityOtpModalVisible,
    setLoyalityOtpModalVisible,
    loyaltyPaymentOtp,
    checkLoyality,
    processOtpInput,
    setLoyaltyPaymentOtp,
    // OMS Order MOdal and Side menu drawer//,
    displayOMSOrderItemsModal,
    omsOrderStatus,
    selectedOMSOrderStatus,
    setSelectedOrder,
    handleOMSOrderStatusSelection,
    order,
    handleOrderSearchInput,
    searchOrders,
    selectedOrder,
    handleOrderCard,
    tillData,
    handleOmsOrders,
    setDisplayOMSOrderItemsModal,
    handleOMSStatusButton,
    omsOrderTotalPrice,
    onClose,
    sideMenuDrawervisible,
    setSideMenuDrawervisible,
    salesRepModalOpen,
    setSalesRepModalOpen,
    handleSalesRepresentive,
    salesRepresentSearchInput,
    setSalesRepresentSearchInput,
    handleSalesRepresentSearchInput,
    filteredSalesRepresentList,

    manualDiscountModalVisible,
    setManualDiscountModalVisible,
    manualDiscountInput,
    setManualDiscountInput,
    manualDiscountTypes,
    setManualDiscountTypes,
    handleManualDiscountKeyPress,
    applyManualDiscount,
    selectedManualDiscountType,
    setSelectedManualDiscountType,
    enableManualDiscountInput,
    setEnableManualDiscountInput,
    removeAllDiscounts,
    setProductWeightModalInput,
    // Paytm QR Code //
    paytmQrCodeModalOpens,
    setPaytmQrCodeModalOpens,
    qrCodeResponse,
    handleVerifyPayment,

    handleSalesReturnFromSideMenu,
    posConfig,
    // Cash management 
    cashIn,
    pettCashIn,
    onChangeCheckbox,
    setAddCashFlag,
    addCashFlag,
    orderHistorySearchInputRef,
    paymentModal,
    handleCahInOut,
    setCashAddInFlag,
    cashAddInFlag,
    cashManagementForm,
    handleCashInOutInput,
    // Gift Card
    giftCardFlag,
    setGiftCardFlag,
    isGiftCardFlag,
    setIsGiftCardFlag,
    giftCardItems,
    setGiftCardItems,
    selectGiftCardItem,
    setSelectGiftCardItem,
    validateGiftCard,
    setValidateGiftCard,
    validateGiftCardForm,
    giftCardBalance,
    setGiftCardBalance,
    giftCardForm,
    handleGiftCardDetails,
    handleGiftCard,
    redeemGiftCard,
    amount,
    setIsInputFocused,
  } = props;
  const { t } = useTranslation();
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const [cashManagementInput,setCashManagementInput] = useState("")
  const issueCardInput = document.getElementById("issueCardInput");
  const issueCardAmount = document.getElementById("issueCardAmount");
  const reddemNumber = document.getElementById("redeemNumber")
  const pinNumb = document.getElementById("pin")
  const redeemAmountNumber = document.getElementById("amountNumber")
  const { width } = useWindowDimensions();
  const handleKey = (e) => {
    const { keyCode } = e;
    if (keyCode === 27) {
      setDisplayManualQtyWeightInput((b) => {
        if (b) {
          return !b;
        } else {
          return b;
        }
      });
    }
    if (keyCode === 87) {
      getWeightFromHardware();
    }
  };

  useEffect(async() => {

    validateGiftCardForm.setFieldsValue({
      amount:cart.isReturn ? Math.abs(cart.total) : ""
    })
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };


  }, []);

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const salesRepModalInputRef = useRef();
  useEffect(() => {
    if (salesRepModalOpen.status) {
      setTimeout(() => {
        salesRepModalInputRef.current.focus();
      }, 100);
    }
  }, [salesRepModalOpen.status]);

  const manualWeightInputRef = useRef();
  useEffect(() => {
    if (displayManualQtyWeightInput) {
      setTimeout(() => {
        manualWeightInputRef.current.focus();
      }, 100);
      getWeightFromHardware();
    }
  }, [displayManualQtyWeightInput]);

  useEffect(()=>{
   validateGiftCardForm.resetFields();
  },[validateGiftCard])

  useEffect(()=>{
    giftCardForm.setFieldsValue({
      amount:amount
     })
  },[giftCardFlag])

  useEffect(()=>{
    if(giftCardBalance>0){
      giftCardForm.setFieldsValue({
        amount:giftCardBalance
       })
    }
  },[giftCardBalance])

  const manualDiscountInputRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (manualDiscountInputRef.current) {
        manualDiscountInputRef.current.focus();
      }
    }, 100);
  }, [selectedManualDiscountType]);

  const getWeightFromHardware = () => {
    if (tillData.tillAccess.cwrTill.hardwareController.weighingScale !== "N") {
      axios
        .get(`${tillData.tillAccess.cwrTill.hardwareController.imageUrl}getWeight`)
        .then((resp) => {
          const responseWeight = resp.data.Weight.replace(" kg", "");
          setProductWeightModalInput(responseWeight);
        })
        .catch((e) => {
          console.error(e);
          //message.error("Failed to get weight !");
        });
    }
  };

  const parkedBillSearchInputRef = useRef();
  useEffect(() => {
    if (displayParkedBillModal) {
      setTimeout(() => {
        // parkedBillSearchInputRef.current.focus();
      }, 100);
    }
  }, [displayParkedBillModal, handleParkedBillSearchInput]);

  const handleCashManagement = (value) => {
    let formData = cashManagementForm.getFieldsValue(true);

      if (formData.amount === "" && value === "x") {
        cashManagementForm.setFieldsValue({
          amount: ""
        })
      } else if (value === "x") {
        cashManagementForm.setFieldsValue({
          amount: `${formData.amount.toString().substring(0, formData.amount.toString().length - 1)}`
        })
      } else {
        cashManagementForm.setFieldsValue({
          amount: `${formData.amount ? formData.amount : ""}${value}`
        })
      }
  }

  const handleCashInNote = (value) => {
    cashManagementForm.setFieldsValue({
      note: value
    });
  }

  const selectGiftCard = async(obj) => {
    await db.giftCardData
    .toArray()
    .then((giftCardData)=> {
      giftCardData.map((giftcard) => {
        if(giftcard.mProductId === obj.mProductId){
          setSelectGiftCardItem({...obj,...giftcard})
        }
      })
    })
    setIsGiftCardFlag(false);
    setValidateGiftCard(true);
  }

  const handleAddGiftCardAmount = (value) => {
    if(!validateGiftCard){
      let formData = giftCardForm.getFieldsValue(true);
      if (value === "clear") {
        if(inputFocused=== "redeemNumber"){
          giftCardForm.setFieldsValue({
            number:""
          })
          reddemNumber.focus();
        }else if(inputFocused === "pin"){
          giftCardForm.setFieldsValue({
            pin:""
          })
          pinNumb.focus();
        }else if(inputFocused === "amount"){
          giftCardForm.setFieldsValue({
            amount:""
          });
          redeemAmountNumber.focus();
        }
        
      } else if (value === "x") {
        if(inputFocused=== "redeemNumber"){
          giftCardForm.setFieldsValue({
            number:`${formData.number.toString().substring(0, formData.number.toString().length - 1)}`
          })
          reddemNumber.focus();
        }else if(inputFocused === "pin"){
          giftCardForm.setFieldsValue({
            pin:`${formData.pin.toString().substring(0, formData.pin.toString().length - 1)}`
          })
          pinNumb.focus();
        }else if(inputFocused === "amount"){
          giftCardForm.setFieldsValue({
            amount:`${formData.amount.toString().substring(0, formData.amount.toString().length - 1)}`
          });
          redeemAmountNumber.focus();
        }
      } else {
        if(inputFocused=== "redeemNumber"){
          giftCardForm.setFieldsValue({
            number:`${formData.number ? formData.number : ""}${value}`
          })
          reddemNumber.focus();
        }else if(inputFocused === "pin"){
          giftCardForm.setFieldsValue({
            pin:`${formData.pin ? formData.pin : ""}${value}`
          })
          pinNumb.focus();
        }else if(inputFocused === "amount"){
          giftCardForm.setFieldsValue({
            amount:`${formData.amount ? formData.amount : ""}${value}`
          });
          redeemAmountNumber.focus();
        }
      }
    }else{
      let formData = validateGiftCardForm.getFieldsValue(true);
      if (value === "clear") {
        if(inputFocused==="issueGiftCardNumber"){
          validateGiftCardForm.setFieldsValue({
            number:""
          });
          issueCardInput.focus();
        }else if(inputFocused === "issueAmount"){
          validateGiftCardForm.setFieldsValue({
            amount:""
          });
          issueCardAmount.focus();
        }
      } else if (value === "x") {
        if(inputFocused==="issueGiftCardNumber"){
          validateGiftCardForm.setFieldsValue({
            number:`${formData.number.toString().substring(0, formData.number.toString().length - 1)}`
          });
          issueCardInput.focus();
        }else if(inputFocused === "issueAmount"){
          validateGiftCardForm.setFieldsValue({
            amount:`${formData.amount.toString().substring(0, formData.amount.toString().length - 1)}`
          });
          issueCardAmount.focus();
        }
      } else {
        if(inputFocused==="issueGiftCardNumber"){
          validateGiftCardForm.setFieldsValue({
            number:`${formData.number ? formData.number : ""}${value}`
          });
          issueCardInput.focus();
        }else if(inputFocused === "issueAmount"){
          if(value === "200" || value === "500" || value === "1000"){
            validateGiftCardForm.setFieldsValue({
              amount: `${formData.amount ? parseFloat(formData.amount) + parseFloat(value) :  parseFloat(value)}`
            });
          }else {
            validateGiftCardForm.setFieldsValue({
              amount: `${formData.amount ? formData.amount : ""}${value}`
            }); 
          }

          issueCardAmount.focus();
        }
       
      }
    }
    
  }

  return (
    <Fragment>
      {/* PARKED BILLS MODAL BEGIN*/}
      <Modal
        // visible={displayParkedBillModal}
        closable={false}
        footer={null}
        // width="35%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row>
          <Col span={9}>
            <ArrowLeftOutlined style={data.posScreenStyles.orderHistoryModal.backArrowIcon} onClick={closeParkedBillModal} />
          </Col>
          <Col span={15}>
            <p style={data.posScreenStyles.parkedBillModal.parkedBillsHistoryModalTitle}>Parked Orders</p>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "12px" }}>
          <Col span={24}>
            <Input
              ref={parkedBillSearchInputRef}
              placeholder={`Customer Search Key`}
              size="large"
              allowClear={false}
              style={data.posScreenStyles.orderHistoryModal.orderHistorySearchInput}
              onPressEnter={searchParkedBill}
              onChange={handleParkedBillSearchInput}
              value={parkedBillSearchInput}
              suffix={<SearchOutlined onClick={searchParkedBill} style={data.posScreenStyles.customerModal.searchIcon} />}
            />
            {/* <Input
              onChange={handleParkedBillSearchInput}
              onPressEnter={searchParkedBill}
              value={parkedBillSearchInput}
              placeholder={`Customer Search Key`}
            /> */}
          </Col>
          {/* <Col span={4}>
            <Button onClick={searchParkedBill}>Search</Button>
          </Col> */}
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {filterdParkedList.map((item, index) => (
                <Card
                  tabIndex={0}
                  onKeyDown={(e) => (e.keyCode === 13 ? selectParkedBill(item) : null)}
                  key={`${index + 1}`}
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 10 }}
                  onClick={() => selectParkedBill(item)}
                >
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.parkedTime}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{index + 1}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.parkedCart.customer.name}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>Parked</p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* PARKED BILLS MODAL END*/}

      {/* ORDER TYPE SELECTION MODAL START*/}
      <Modal
        visible={displaySetOrderType}
        closable={false}
        footer={null}
        // width="35%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row>
          <Col span={14}>
            <p style={data.posScreenStyles.orderTypeSelectionModal.modalTitle}>Order Type</p>
          </Col>
          <Col span={10}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayOrderType(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {posSaleTypes.map((item, index) => (
                <Card
                  key={`${item.name}-${index}`}
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 20 }}
                  onClick={() => changeOrderType(item)}
                >
                  <Row>
                    <Col span={24}>
                      <p style={data.posScreenStyles.orderTypeSelectionModal.orderTypeName}>{item.cwrSaletype.name}</p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* ORDER TYPE SELECTION MODAL END*/}

      {/* OFFER SELECTION MODAL BEGIN*/}
      <Modal
        visible={displayOfferProductSelectiton}
        footer={null}
        closable={true}
        bodyStyle={{ padding: 0 }}
        // width="30%"
        onCancel={() => setDisplayOfferProductSelection(false)}
      >
        <Row style={data.posScreenStyles.offerSelectionModal.mainRow}>
          <Col span={24}>
            <p style={data.posScreenStyles.offerSelectionModal.title}>Select an Offer Product</p>
          </Col>
          {/* <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayOfferProductSelection(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col> */}
        </Row>
        {/* {offerProductsList
          ? offerProductsList?.map((offerData, index) => (
              <Row key={`${offerData.name}-${index}`} style={data.posScreenStyles.offerSelectionModal.offerBtnsRow}>
                <Col span={24}>
                  {console.log(offerData,"offerData-----------")}
                  <Button size="large" onClick={() => selectOfferProduct(offerData)} style={data.posScreenStyles.offerSelectionModal.offerBtn}>
                    {offerData.name}
                  </Button>
                </Col>
              </Row>
            ))
          : ""} */}
        {offerProductsList?.length>0 ? <Scrollbars autoHide className="offerProductScroll1">
          {offerProductsList.map((obj, i) => (
            <Row gutter={[15, 11]} style={{ width: "99%", backgroundColor: "#fff", marginTop: "1.5vh", height: "11vh", marginLeft: "-0.1vw", borderRadius: "10px" }}>
              <Col span={4}>
                <img
                  onError={setDefaultImage}
                  style={{
                    width: "100%",
                    height: "10vh",
                    objectFit: "contain",
                    borderRadius: "5px",
                    paddingTop: "1vh",
                    alignSelf: "center"
                  }}
                  src={obj.imageurl || DefaultProductImage}
                  alt=""
                />
              </Col>
              <Col span={16}>
                <label style={data.posScreenStyles.productsCard.cardDetails.productDescr} className="productName">
                  {obj["description"] || "description"}
                </label>
                <p style={data.posScreenStyles.productsCard.cardDetails.superMarketProd} className="productName">
                  {obj["name"]}
                </p>
              </Col>
              <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <span onClick={() => {selectOfferProduct(obj);}} style={{ fontSize: "1.5vh", color: "#fff", backgroundColor: "#2F3856", padding: "1vh 2vw", marginTop: "2%", borderRadius: "3px", cursor: "pointer" }}>ADD</span>
              </Col>
              <span tabIndex={0}></span>
            </Row>
          ))}

        </Scrollbars> : ""}
      </Modal>
      {/* OFFER SELECTION MODAL END*/}

      {/* BATCH SELECTION MODAL START*/}
      <Modal
        visible={displayBatchSelection}
        closable={false}
        keyboard={false}
        footer={null}
        // onCancel={() => setDisplayBatchSelection(false)}
        // width="35%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.title}>Product Batch</p>
          </Col>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayBatchSelection(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {batchSetAvailable.map((item, index) => (
                <Card
                  key={`${item.batchno}-${index}`}
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 10 }}
                  onClick={() => selectProductToCart(item)}
                  onKeyPress={(e) => (e.charCode === 13 ? selectProductToCart(item) : null)}
                  className="focusDashboardCard"
                  tabIndex={0}
                >
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderDate}>Batch</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Mrp</p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Price</p>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.batchno}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {parseFloat(item.mrpPrice).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {parseFloat(item.salePrice).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</p>
                    </Col> */}
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* BATCH SELECTION MODAL END*/}

      {/* PRODUCT WEIGHT MODAL START */}
      <Modal visible={displayManualQtyWeightInput} closable={false} keyboard={false} footer={null} width="45%" bodyStyle={{ padding: 10 }}>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <CloseOutlined
              onClick={() => {
                setProductWeightModalInput("");
                setDisplayManualQtyWeightInput(false);
              }}
              style={{ fontSize: "18px" }}
            />
          </Col>
        </Row>
        <Row gutter={[8, 0]}>
          <Col span={9}>
            <Card bodyStyle={{ padding: 10 }}>
              <img onError={setDefaultImage} style={data.posScreenStyles.productWeightModal.imgStyles} src={currentWeightSelectedProduct.imageurl} alt="" />
            </Card>
            <Row style={{ paddingTop: 10 }}>
              <h3 style={data.posScreenStyles.productWeightModal.prodHead}>{currentWeightSelectedProduct.name || ""}</h3>
            </Row>
            <Row>
              <Col span={9}>
                <h3 style={data.posScreenStyles.productWeightModal.prodHead}>MRP</h3>
                <h3 style={data.posScreenStyles.productWeightModal.prodHead}>Quantity</h3>
              </Col>
              <Col span={15}>
                <p style={data.posScreenStyles.productWeightModal.prodPriceValue}>Rs. {(currentWeightSelectedProduct.salePrice || 0).toFixed(2)}</p>
                <p style={data.posScreenStyles.productWeightModal.prodPriceValue}>{productWeightModalInput || 0}</p>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col span={24}>
                <Button type="dashed" className="add-payment-btn-white" style={data.posScreenStyles.paymentModal.addpaymentbtnWhite} onClick={getWeightFromHardware}>
                  Get Weight
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={15}>
            <Card bodyStyle={{ padding: 15 }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Input
                    ref={manualWeightInputRef}
                    value={productWeightModalInput}
                    onChange={onProductModalChangeWeight}
                    onPressEnter={addManualWeightToProduct}
                    style={data.posScreenStyles.paymentModal.topayInput}
                    size="large"
                  />
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("1")}>
                      1
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("2")}>
                      2
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("3")}>
                      3
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("x")}>
                      <img src={Backspace} alt="back space" width={20} height={15} />
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("4")}>
                      4
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("5")}>
                      5
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("6")}>
                      6
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual(".")}>
                      .
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("7")}>
                      7
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("8")}>
                      8
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("9")}>
                      9
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1.1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("0")}>
                      0
                    </button>
                  </AspectRatio>
                </Col>
                <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={addManualWeightToProduct}>
                  <img src={Tick} alt="" />
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Modal>
      {/* PRODUCT WEIGHT MODAL END */}

      {/* COUPON MODAL START */}
      <Modal closable={true} onCancel={closeCouponModal} footer={null} onclosefooter={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row style={{ textAlign: "center" }}>Enter Coupon Code</Row>
        <Row style={{ padding: "30px" }}>
          <Col span={20}>
            <Input onPressEnter={checkCoupon} onChange={(e) => setCouponInput(e.target.value)} />
          </Col>
          <Col span={4}>
            <Button onClick={checkCoupon}>Apply</Button>
          </Col>
        </Row>
      </Modal>
      <Drawer
        placement="bottom"
        closable={false}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ fontSize: "2vw", paddingTop: "3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Coupon Code</p>
            <img src={Close} onClick={closeCouponModal} style={{ marginLeft: "auto", paddingBottom: "5vh", cursor: "pointer" }} />
          </div>
        }
        visible={couponModalVisible}
        className="filter"
        bodyStyle={{ paddingBottom: 0 }}
        onClose={() => {
          setManualDiscountInput("");
          closeCouponModal()
        }}
        //  id="sm-drawer-close"
        height="75vh">
        <Row>
          <Col span={2} />
          <Col span={9} style={{ paddingRight: "1vw" }}>
            <Row style={{ marginTop: "" }}>
              <Col span={24}>
                <p style={{ marginBottom: "10px", marginTop: "12px", fontWeight: "600", fontSize: "1vw" }}>Coupon Code</p>
                <Input
                  // ref={manualDiscountInputRef}
                  // disabled={enableManualDiscountInput}
                  // value={manualDiscountInput}
                  onPressEnter={couponModalVisible?checkCoupon:null}
                  onChange={(e) => setCouponInput(e.target.value)}
                  value={couponInput}
                  onFocus={()=>{setIsInputFocused(true);}}
                  style={{ width: "100%", borderRadius: "4px",height:"7.7vh" }}
                  size="large"
                  className="discount-value-input"
                  placeholder="Type Code"
                />
              </Col>
            </Row>
          </Col>
          <Col span={2} />
          <Col span={11} style={{ marginBottom: "5vh" }}>
            <Row gutter={[8, 8]} style={{ padding: "0 1% 0 3.5%" }}>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("1")}>
                  1
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("2")}>
                  2
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("3")}>
                  3
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("x")}>
                  <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                </button>
              </Col>
              <Col span={4} />
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("4")}>
                  4
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("5")}>
                  5
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("6")}>
                  6
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market-cancel-discount" onClick={() => handleCouponInput("clear")}>Clear</button>
              </Col>
              <Col span={4} />
              <Col span={15}>
                <Row gutter={[8, 6]}>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("7")}>
                      7
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("8")}>
                      8
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("9")}>
                      9
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("0")}>
                      0
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleCouponInput("00")}>
                      00
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" >
                      .
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col span={5}>
                <button
                  // id="sm-amount-buttonEnter"
                  className="amt-dial-btn-market-discount"
                  onClick={checkCoupon}
                >
                  Enter
                </button>
              </Col>
              <Col span={4} />
              {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
            </Row>
            <Row style={{ marginTop: "2vh", padding: "0 1% 0 3.5%" }} gutter={[8, 8]}>
              <Col span={10}>
                <Button className="discount-remove-btn"         
                onClick={() => {
                  setManualDiscountInput("");
                  closeCouponModal()
                }}>
                  Cancel
                </Button>
              </Col>
              <Col span={10} >
                <Button className="discount-apply-btn" onClick={checkCoupon} >
                  Apply
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
      {/* COUPON MODAL END */}

      {/* LOYALITY OTP MODAL START */}
      <Modal closable={true} onCancel={() => {
        setLoyalityOtpModalVisible(false);
        setLoyaltyPaymentOtp("");
      }} footer={null} onclosefooter={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row style={{ textAlign: "center" }}>Enter OTP</Row>
        <Row style={{ padding: "30px" }}>
          <Col span={20}>
            <Input onPressEnter={processOtpInput} onChange={(e) => setLoyaltyPaymentOtp(e.target.value)} value={loyaltyPaymentOtp} />
          </Col>
          <Col span={4}>
            <Button onClick={processOtpInput}>Verify</Button>
          </Col>
        </Row>
      </Modal>
      <Drawer
        placement="bottom"
        closable={false}
        // title={
        //   <div style={{ display: "flex", alignItems: "center" }}>
        //     <p style={{ fontSize: "1.6vw", paddingTop: "3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Loyalty Points</p>
        //     <img src={Close} onClick={()=>setLoyalityOtpModalVisible(false)} style={{ marginLeft: "auto", paddingBottom: "5vh", cursor: "pointer" }} />
        //   </div>
        // }
        visible={loyalityOtpModalVisible}
        className="filter"
        bodyStyle={{ paddingBottom: 0 }}
        onClose={() => {
          setLoyalityOtpModalVisible(false);
          setLoyaltyInputValue("");
        }}
        //  id="sm-drawer-close"
        height="80vh">
        <div style={{ display: "flex", alignItems: "center" ,paddingBottom:"2vh"}}>
            <p style={{ fontSize: "1.3vw", paddingTop: "1vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" ,margin:0}}>Redeem Loyalty</p>
            <img src={Close} onClick={()=>setLoyalityOtpModalVisible(false)} style={{ marginLeft: "auto", cursor: "pointer" }} />
        </div>
        <Row>
          <Col span={2} />
          <Col span={9} style={{ paddingRight: "1vw" }}>
            <Row style={{ marginTop: "" }}>
              <Col span={24}>
                <p style={{ marginBottom: "10px", fontWeight: "600", fontSize: "1vw" }}>Loyalty Membership</p>
                <p style={{ marginBottom: "10px", fontWeight: "600", fontSize: "2.5vw" }}>{cart.customer.loyaltyLevel?.name}</p>
              </Col>

              <Col span={24} style={{paddingTop:"10vh"}}>
                <p style={{ marginBottom: "10px", fontWeight: "600", fontSize: "1vw" }}>Loyalty Member Since</p>
                <p style={{ marginBottom: "10px", fontWeight: "600", fontSize: "2.5vw" }}>10/08/2023</p>
              </Col>

              <Col span={24} style={{paddingTop:"10vh"}}>
                <p style={{ marginBottom: "10px", fontWeight: "600", fontSize: "1vw" }}>Loyalty Points Available</p>
                <p style={{ marginBottom: "10px", fontWeight: "600", fontSize: "2.5vw" }}>{Math.abs(cart.customer.retlLoyaltyBalance).toFixed(2)}</p>
              </Col>

            </Row>
          </Col>
          <Col span={2} />
          <Col span={11} style={{ marginBottom: "0vh" }}>
          <Row style={{ padding: "0 1% 3vh 3.5%" }} gutter={[8, 8]}>
              <Col span={20}>
              <p style={{ marginBottom: "10px", fontWeight: "600", fontSize: "1vw" }}>Enter Points to redeem</p>
                <Input
                  // ref={manualDiscountInputRef}
                  // disabled={enableManualDiscountInput}
                  // value={manualDiscountInput}
                  onPressEnter={()=>{
                    if(Math.abs(cart.customer.retlLoyaltyBalance)>=loyaltyInputValue){
                      checkLoyality()
                    }else{
                      {message.error(`${t('loyalty_redeemption_error')}`)}
                    }
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^[0-9]*$/.test(value)) {
                      setLoyaltyInputValue(value);
                    }
                  }}
                  value={loyaltyInputValue}
                  style={{ width: "100%", borderRadius: "2px",height:"9.5vh" }}
                  size="large"
                  className="discount-value-input"
                  placeholder="Enter value"
                />
              </Col>
            </Row>
            <Row gutter={[8, 8]} style={{ padding: "0 1% 0 3.5%" }}>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("1")}>
                  1
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("2")}>
                  2
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("3")}>
                  3
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("x")}>
                  <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                </button>
              </Col>
              <Col span={4} />
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("4")}>
                  4
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("5")}>
                  5
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("6")}>
                  6
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market-cancel-discount" onClick={() => handleLoyalityInput("clear")}>Clear</button>
              </Col>
              <Col span={4} />
              <Col span={15}>
                <Row gutter={[8, 6]}>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("7")}>
                      7
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("8")}>
                      8
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("9")}>
                      9
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("0")}>
                      0
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput("00")}>
                      00
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleLoyalityInput(".")}>
                      .
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col span={5}>
                <button
                  // id="sm-amount-buttonEnter"
                  className="amt-dial-btn-market-discount"
                  onClick={()=>{
                    if(Math.abs(cart.customer.retlLoyaltyBalance)>=loyaltyInputValue){
                      checkLoyality()
                    }else{
                      {message.error(`${t('loyalty_redeemption_error')}`)}
                    }
                  }}
                >
                  Enter
                </button>
              </Col>
              <Col span={4} />
              {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
            </Row>
            <Row style={{ marginTop: "2vh", padding: "0 1% 0 3.5%" }} gutter={[8, 8]}>
              <Col span={20}>
                <Button className="discount-apply-btn" onClick={()=>{
                   if(Math.abs(cart.customer.retlLoyaltyBalance)>=loyaltyInputValue){
                    checkLoyality()
                  }else{
                    {message.error(`${t('loyalty_redeemption_error')}`)}
                  }
                }} >
                  Redeem
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
      {/* LOYALITY OTP MODAL END */}

      {/* OMS Orders Modal Starts */}
      <Modal
        closable={false}
        footer={null}
        bodyStyle={{ padding: 0, height: "100%" }}
        visible={displayOMSOrderItemsModal}
        // onCancel={handleAddOnModalClose}
        style={{ top: 50 }}
        width="90%"
      >
        <Row>
          <Col span={2}>
            <Row>
              {omsOrderStatus.map((statusItem, index) => (
                <Col
                  span={24}
                  style={
                    statusItem?.title === selectedOMSOrderStatus.title
                      ? data.posScreenStyles.omsOrdersModal.selectedOMSOrderStatusOptionsCol
                      : data.posScreenStyles.omsOrdersModal.omsOrderStatusOptionsCol
                  }
                  onClick={() => {
                    setSelectedOrder({});
                    handleOMSOrderStatusSelection(statusItem);
                  }}
                >
                  <img
                    src={statusItem?.title === selectedOMSOrderStatus.title ? statusItem?.imgSrc : statusItem?.selectedImgSrc}
                    alt={statusItem?.imgSrc}
                    style={{ height: "46px" }}
                  />
                  <h6
                    style={
                      statusItem?.title === selectedOMSOrderStatus.title
                        ? data.posScreenStyles.omsOrdersModal.selectedOMSStatusOptionName
                        : data.posScreenStyles.omsOrdersModal.omsStatusOptionName
                    }
                  >
                    {statusItem?.title}
                  </h6>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={24}>
                <p style={data.posScreenStyles.omsOrdersModal.manageOrders}>Manage Orders</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  size="large"
                  placeholder="Search Order No"
                  value={order}
                  onChange={(e) => {
                    handleOrderSearchInput(e.target.value);
                  }}
                  allowClear
                  suffix={<SearchOutlined style={{ fontSize: "14px" }} />}
                  className="orderIdInput"
                  style={data.posScreenStyles.omsOrdersModal.orderIdInput}
                />
                <div style={{ backgroundColor: "#F5F5F7", marginTop: "3px" }}>
                  <Scrollbars autoHide className="omsOrderCardsScroll">
                    {searchOrders && searchOrders.length > 0
                      ? _.filter(searchOrders, (searchItem) => searchItem.status === selectedOMSOrderStatus.statusValue)?.map((item, index) => (
                        <div>
                          <Card
                            bodyStyle={{ padding: 10 }}
                            style={
                              item.orderNo === selectedOrder.orderNo
                                ? data.posScreenStyles.omsOrdersModal.selectedOrderDetailsCard
                                : data.posScreenStyles.omsOrdersModal.orderDetailsCard
                            }
                            onClick={() => handleOrderCard(item)}
                          >
                            <p style={data.posScreenStyles.omsOrdersModal.orderIdValue}>OrderID: #{item.orderNo}</p>
                            <p style={data.posScreenStyles.omsOrdersModal.itemsNum}>
                              {item.noOfItems} items for {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                              {_.sumBy(item.lineItems, "price").toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                            </p>
                            <p style={data.posScreenStyles.omsOrdersModal.dateCreated}>{item.dateCreated}</p>
                          </Card>
                          {/* <hr style={{ margin: 0, backgroundColor: "#FFFFFF11" }} /> */}
                        </div>
                      ))
                      : ""}
                  </Scrollbars>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={16} style={{ padding: "3px 10px" }}>
            <Row>
              <Col span={12}>
                <p style={data.posScreenStyles.omsOrdersModal.omsCustTitle}>{tillData.tillAccess.csBunit.name}</p>
              </Col>
              <Col span={11} style={{ paddingTop: 5 }}>
                <p style={data.posScreenStyles.omsOrdersModal.closeIconP}>
                  <SyncOutlined onClick={() => handleOmsOrders()} style={data.posScreenStyles.omsOrdersModal.closeIcon} />
                </p>
              </Col>
              <Col span={1} style={{ paddingTop: 5 }}>
                <p style={data.posScreenStyles.omsOrdersModal.closeIconP}>
                  <CloseOutlined
                    onClick={() => {
                      setDisplayOMSOrderItemsModal(false);
                      // localStorage.setItem('orderDetails', "[]");
                    }}
                    style={data.posScreenStyles.omsOrdersModal.closeIcon}
                  />
                </p>
              </Col>
            </Row>
            {Object.keys(selectedOrder).length > 0 ? (
              <>
                <Row style={{ marginTop: "2rem" }}>
                  <Col span={12}>
                    <p style={data.posScreenStyles.omsOrdersModal.storeItems}>Store Items</p>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display:
                        selectedOMSOrderStatus.title === "Today's Orders" || selectedOMSOrderStatus.statusValue !== selectedOrder.status || selectedOrder.posOrders === "Y"
                          ? "none"
                          : "block",
                    }}
                  >
                    <Button size="medium" style={data.posScreenStyles.omsOrdersModal.statusBtn} className="addButton" onClick={() => handleOMSStatusButton(selectedOrder)}>
                      <span style={data.posScreenStyles.omsOrdersModal.statusBtn.label}>
                        {selectedOrder.status === "NE" ? "Accept" : selectedOrder.status === "UP" ? "Prepared" : selectedOrder.status === "PK" ? "Completed" : "Accept"}
                      </span>
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Card bodyStyle={{ padding: 0 }} style={data.posScreenStyles.omsOrdersModal.omsOrderDetails}>
                      <Row
                        style={{
                          padding: 10,
                          paddingBottom: 5,
                          borderBottom: "1px solid #DCDCDC",
                        }}
                      >
                        <Col span={12}>
                          <p style={data.posScreenStyles.omsOrdersModal.omsOrderCustDetails}>OrderID. #{selectedOrder.orderNo}</p>
                          <p style={data.posScreenStyles.omsOrdersModal.omsOrderCustDetails}>{selectedOrder.customerName || "Anonymous"}</p>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <p style={data.posScreenStyles.omsOrdersModal.omsOrderCustDetails}>{selectedOrder.dateCreated}</p>
                          <p style={data.posScreenStyles.omsOrdersModal.omsOrderCustDetails}>{selectedOrder.noOfItems} Items</p>
                        </Col>
                      </Row>
                      <div style={{ borderBottom: "1px solid #DCDCDC" }}>
                        <Row
                          style={{
                            padding: 10,
                            paddingBottom: 0,
                          }}
                        >
                          <Col span={8}>
                            <p style={data.posScreenStyles.omsOrdersModal.tableHeadingName}>Item</p>
                          </Col>
                          <Col span={4}>
                            <p style={data.posScreenStyles.omsOrdersModal.tableHeadingQty}>Qty</p>
                          </Col>
                          <Col span={6}>
                            <p style={data.posScreenStyles.omsOrdersModal.tableHeadingPrice}>Price</p>
                          </Col>
                          <Col span={6}>
                            <p style={data.posScreenStyles.omsOrdersModal.tableHeadingPrice}>Total Price</p>
                          </Col>
                        </Row>
                        <Scrollbars autoHide className="orderDetailsTableScroll">
                          {selectedOrder?.lineItems?.map((productItem, index) => (
                            <Row
                              style={{
                                padding: 10,
                                paddingBottom: 0,
                              }}
                            >
                              <Col span={8}>
                                <p style={data.posScreenStyles.omsOrdersModal.tableDataName}>
                                  <img onError={setDefaultImage} src={productItem.img} alt="" style={data.posScreenStyles.omsOrdersModal.productImg} />
                                  <div
                                    style={{
                                      paddingLeft: 10,
                                      alignSelf: "center",
                                    }}
                                  >
                                    {productItem.name}
                                    <br />
                                    <span style={data.posScreenStyles.omsOrdersModal.tableDataProdCode}>{productItem.productCode}</span>
                                  </div>
                                </p>
                              </Col>
                              <Col span={4} style={{ paddingTop: 7 }}>
                                <p style={data.posScreenStyles.omsOrdersModal.tableDataQty}>x &nbsp;&nbsp;&nbsp; {productItem.quantity}</p>
                              </Col>
                              <Col span={6} style={{ paddingTop: 7 }}>
                                <p style={data.posScreenStyles.omsOrdersModal.tableDataPrice}>Rs.{productItem.price.toFixed(2)}</p>
                              </Col>
                              <Col span={6} style={{ paddingTop: 7 }}>
                                <p style={data.posScreenStyles.omsOrdersModal.tableDataPrice}>Rs.{productItem.price.toFixed(2)}</p>
                              </Col>
                            </Row>
                          ))}
                        </Scrollbars>
                      </div>
                      <Row
                        style={{
                          padding: 10,
                        }}
                      >
                        <Col span={12} />
                        <Col span={6}>
                          <p style={data.posScreenStyles.omsOrdersModal.subTotalTaxes}>Sub Total</p>
                          <p style={data.posScreenStyles.omsOrdersModal.subTotalTaxes}>Taxes</p>
                          <p style={data.posScreenStyles.omsOrdersModal.total}>Total</p>
                        </Col>
                        <Col span={6}>
                          <p style={data.posScreenStyles.omsOrdersModal.subTotalTaxes}>Rs.{(omsOrderTotalPrice || 0).toFixed(2)}</p>
                          <p style={data.posScreenStyles.omsOrdersModal.subTotalTaxes}>Rs.0</p>
                          <p style={data.posScreenStyles.omsOrdersModal.total}>Rs.{(omsOrderTotalPrice || 0).toFixed(2)}</p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Modal>
      {/* OMS Orders Modal Ends */}

      {/* Side Menu Drawer Starts */}
      <Drawer title={<img src={POSLogo} style={data.posScreenStyles.header.posLogoIcon} alt="" />} placement="left" onClose={onClose} visible={sideMenuDrawervisible}>
        <Row style={{ paddingLeft: "20px" }}>
          <Col span={2}>
            <img src={MobileIcon} alt="Mobile Icon" style={{ width: "10px" }} />
          </Col>
          <Col span={22}>
            <p
              style={{
                marginBottom: 0,
                fontSize: "14px",
                fontWeight: "normal",
                fontFamily: "Inter",
                letterSpacing: "0px",
                color: "#000000",
                opacity: 1,
                cursor: "pointer",
              }}
              onClick={handleOmsOrders}
            >
              OMS Orders
            </p>
          </Col>
        </Row>
        <Row style={{ paddingLeft: "20px", paddingTop: "20px" }}>
          <Col span={2}>
            <img src={userIcon} alt="Mobile Icon" style={{ width: "15px" }} />
          </Col>
          <Col span={22}>
            <p
              style={{
                marginBottom: 0,
                fontSize: "14px",
                fontWeight: "normal",
                fontFamily: "Inter",
                letterSpacing: "0px",
                color: "#000000",
                opacity: 1,
                cursor: "pointer",
              }}
            >
              {cart.customer.name}&nbsp; &nbsp;{cart.customer.retlLoyaltyBalance || 0}
            </p>
          </Col>
        </Row>
        <div style={{ display: posConfig.showSalesReturn === "Y" ? "block" : "none" }}>
          <Row style={{ paddingLeft: "20px", paddingTop: "20px" }}>
            <Col span={2}>
              <img src={userIcon} alt="Mobile Icon" style={{ width: "15px" }} />
            </Col>
            <Col span={22}>
              <p
                style={{
                  marginBottom: 0,
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontFamily: "Inter",
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: 1,
                  cursor: "pointer",
                }}
                onClick={handleSalesReturnFromSideMenu}
              >
                Sales Return
              </p>
            </Col>
          </Row>
        </div>
      </Drawer>
      {/* Side Menu Drawer Ends */}

      {/* Sales Representative Modal Starts */}
      <Modal visible={salesRepModalOpen.status} closable={false} footer={null} keyboard={false} bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row>
          <Col span={7}>
            <ArrowLeftOutlined
              style={data.posScreenStyles.orderHistoryModal.backArrowIcon}
              onClick={() => {
                setSalesRepModalOpen({ status: false, title: "" });
                setSalesRepresentSearchInput("");
              }}
            />
          </Col>
          <Col span={17}>
            <p style={data.posScreenStyles.parkedBillModal.parkedBillsHistoryModalTitle}>Sales Representative</p>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "12px" }}>
          <Col span={24}>
            <Input
              ref={salesRepModalInputRef}
              placeholder={`Sales Representative Search`}
              size="large"
              allowClear={false}
              style={data.posScreenStyles.orderHistoryModal.orderHistorySearchInput}
              onChange={(e) => handleSalesRepresentSearchInput(e.target.value)}
              value={salesRepresentSearchInput}
              suffix={<SearchOutlined style={data.posScreenStyles.customerModal.searchIcon} />}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          {filteredSalesRepresentList.map((item, index) => (
            <Col span={8}>
              <Card
                tabIndex={0}
                bodyStyle={{ padding: "12px" }}
                key={`${item.name}-${index}`}
                className="salesRepresentativeCard focusDashboardCard"
                onClick={() => handleSalesRepresentive(item)}
                onKeyPress={(e) => (e.charCode === 13 ? handleSalesRepresentive(item) : null)}
              >
                <p className="representativeName">{item.name}</p>
              </Card>
            </Col>
          ))}
          <Col span={8}>
            <Card
              tabIndex={0}
              bodyStyle={{ padding: "12px" }}
              key={`none-key`}
              className="salesRepresentativeCard focusDashboardCard"
              onClick={() => handleSalesRepresentive({ salesRepresentId: null, name: null })}
              onKeyPress={(e) => (e.charCode === 13 ? handleSalesRepresentive({ salesRepresentId: null, name: null }) : null)}
            >
              <p className="representativeName">None</p>
            </Card>
          </Col>
        </Row>
      </Modal>
      {/* Sales Representative Modal Ends */}

      {/* MANUAL DISCOUNT MODAL START */}
      {/* <Modal
       title={<div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
       <p style={{fontSize:"2vw", fontWeight:"500",paddingTop:"2%",paddingLeft:"3%"}}>Add Discount</p>
       </div>}
       closeIcon={<img src={Close}/>}
        destroyOnClose={true}
        visible={manualDiscountModalVisible}
        closable={true}
        centered
        onCancel={() => {
          setManualDiscountInput("");
          setManualDiscountModalVisible(false);
        }}
        footer={null}
        width="50%"
        bodyStyle={{padding:"1% 7%",background:"#f3f4f9"}}
      >        
        <Row>
          <Col span={13} style={{paddingRight:"1vw"}}>
            <Row style={{marginTop:"3vh"}}>
              <Col span={24}>
                <p style={{ marginBottom: "0px" ,fontSize:"1vw",fontWeight:"600"}}>Discount Type</p>
                <Select className="custom-select"
                placeholder="Select Type"
                suffixIcon={<img src={SelectIcon} alt="" height="5px"/>}
                onChange={setSelectedManualDiscountType} style={{ width: "100%", borderRadius:"4px" }}>
                  {manualDiscountTypes.map((md) => (
                    <Option value={md.mPricingrulesId}>{md.name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row style={{marginTop:"4vh"}}>
              <Col span={24}>
                <p style={{ marginBottom: "0px", marginTop: "12px" ,fontWeight:"600",fontSize:"1vw"}}>Value</p>
                <Input
                  ref={manualDiscountInputRef}
                  disabled={enableManualDiscountInput}
                  value={manualDiscountInput}
                  onPressEnter={applyManualDiscount}
                  onChange={(e) => setManualDiscountInput(e.target.value)}
                  style={{ width: "100%", borderRadius:"4px" }}
                  size="large"
                  className="discount-value-input"
                  placeholder="Enter Value"
                />
              </Col>
            </Row>
            <Row style={{marginTop:"7vh"}} gutter={[8,8]}>
                <Col span={12}>
                <Button className="discount-remove-btn" onClick={removeAllDiscounts}>
                  Remove Discount 
                </Button>
                </Col>
                <Col span={12} >
                <Button className="discount-apply-btn" onClick={applyManualDiscount} >
                  Apply
                </Button>
                </Col>
            </Row>  
          </Col>
          <Col span={11} style={{marginBottom:"5vh"}}>
              <Row gutter={[8, 8]} style={{ padding: "0 1% 0 3.5%" }}>
                <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("1")}>
                      1
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("2")}>
                      2
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("3")}>
                      3
                    </button>
                 
                </Col>

                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("4")}>
                      4
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("5")}>
                      5
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("8")}>
                      6
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("7")}>
                      7
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("8")}>
                      8
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("9")}>
                      9
                    </button>
                 
                </Col>

                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress(".")}>
                      .
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("0")}>
                      0
                    </button>
                 
                </Col>
                <Col span={8}>
                  
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("x")}>
                      <img src={CancelArrow} alt="back space" width={20} height={15} />
                    </button>
                 
                </Col>
              </Row>
            
          </Col>
        </Row>
      </Modal> */}
      <Drawer
        placement="bottom"
        closable={false}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ fontSize: "2vw", paddingTop: "3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Add Discount</p>
            <img src={Close} onClick={() => { setManualDiscountInput(""); setManualDiscountModalVisible(false); }} style={{ marginLeft: "auto", paddingBottom: "5vh", cursor: "pointer" }} />
          </div>
        }
        visible={manualDiscountModalVisible}
        className="filter"
        bodyStyle={{ paddingBottom: 0 }}
        onClose={() => {
          setManualDiscountInput("");
          setManualDiscountModalVisible(false);
        }}
        //  id="sm-drawer-close"
        height="75vh">
        <Row>
          <Col span={2} />
          <Col span={9} style={{ paddingRight: "1vw" }}>
            <Row style={{ marginTop: "3vh" }}>
              <Col span={24}>
                <p style={{ marginBottom: "10px", fontSize: "1vw", fontWeight: "600" }}>Discount Type</p>
                <Select className="custom-select"
                  placeholder="Select Type"
                  suffixIcon={<img src={SelectIcon} alt="" height="5px" />}
                  onChange={setSelectedManualDiscountType} style={{ width: "100%", borderRadius: "4px" }}>
                  {manualDiscountTypes.map((md) => (
                    <Option value={md.mPricingrulesId}>{md.name}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: "7vh" }}>
              <Col span={24}>
                <p style={{ marginBottom: "10px", marginTop: "12px", fontWeight: "600", fontSize: "1vw" }}>Value</p>
                <Input
                  ref={manualDiscountInputRef}
                  disabled={enableManualDiscountInput}
                  value={manualDiscountInput}
                  onPressEnter={applyManualDiscount}
                  onChange={(e) => setManualDiscountInput(e.target.value)}
                  style={{ width: "100%", borderRadius: "4px",height:"7.7vh" }}
                  size="large"
                  className="discount-value-input"
                  placeholder="Enter Value"
                />
              </Col>
            </Row>
          </Col>
          <Col span={2} />
          <Col span={11} style={{ marginBottom: "5vh" }}>
            <Row gutter={[8, 8]} style={{ padding: "0 1% 0 3.5%" }}>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("1")}>
                  1
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("2")}>
                  2
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("3")}>
                  3
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("x")}>
                  <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                </button>
              </Col>
              <Col span={4} />
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("4")}>
                  4
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("5")}>
                  5
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("6")}>
                  6
                </button>
              </Col>
              <Col span={5}>
                <button className="amt-dial-btn-super-market-cancel-discount" >Clear</button>
              </Col>
              <Col span={4} />
              <Col span={15}>
                <Row gutter={[8, 6]}>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("7")}>
                      7
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("8")}>
                      8
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("9")}>
                      9
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("0")}>
                      0
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("00")}>
                      00
                    </button>
                  </Col>
                  <Col span={8}>
                    <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress(".")}>
                      .
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col span={5}>
                <button
                  // id="sm-amount-buttonEnter"
                  className="amt-dial-btn-market-discount"
                >
                  Enter
                </button>
              </Col>
              <Col span={4} />
              {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
            </Row>
            <Row style={{ marginTop: "2vh", padding: "0 1% 0 3.5%" }} gutter={[8, 8]}>
              <Col span={10}>
                <Button className="discount-remove-btn" onClick={removeAllDiscounts}>
                  Remove Discount
                </Button>
              </Col>
              <Col span={10} >
                <Button className="discount-apply-btn" onClick={applyManualDiscount} >
                  Apply
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
      {/* MANUAL DISCOUNT MODAL END */}

      {/* Paytm QR Code Modal Starts */}
      <Modal
        visible={paytmQrCodeModalOpens}
        closable={false}
        okText="Verify"
        keyboard={false}
        bodyStyle={{ padding: "20px 15px 15px" }}
        onOk={handleVerifyPayment}
        onCancel={() => {
          setPaytmQrCodeModalOpens(false);
        }}
      >
        <Row>
          <Col span={7}>
            <ArrowLeftOutlined
              style={data.posScreenStyles.orderHistoryModal.backArrowIcon}
              onClick={() => {
                setPaytmQrCodeModalOpens(false);
              }}
            />
          </Col>
          <Col span={17}>
            <p style={data.posScreenStyles.parkedBillModal.parkedBillsHistoryModalTitle}>Paytm QR Code</p>
          </Col>
        </Row>
        {qrCodeResponse && qrCodeResponse.body && qrCodeResponse.body.qrData && (
          <Row
            style={{
              justifyContent: "center",
              padding: "30px 0px",
            }}
          >
            <QRCode value={qrCodeResponse.body.qrData} />
          </Row>
        )}
      </Modal>
      {/* Paytm QR Code Modal Ends */}

      {/* Cash Management Code Modal Starts */}
      <Modal  centered width="50%" bodyStyle={{ padding: "1% 7%", background: "#f3f4f9" }} footer={null} closeIcon={<img src={Close} />} closable={true} onCancel={() => setAddCashFlag(false)}
        title={<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <p style={{ fontSize: "2vw", fontWeight: "500", paddingTop: "2%", paddingLeft: "3%", color: "#0F0718" }}>{cashAddInFlag ? "Remove Cash" : "Add Cash"}</p>
          {/* <img src={Close} /> */}
        </div>}>
        <Form form={cashManagementForm} onFinish={handleCahInOut} layout="vertical">
          <Row style={{ padding: "3% 0 6% 0" }}>
            <Col span={13} style={{ paddingRight: "1vw" }}>
              {cashAddInFlag ? <Row>
                <Col span={12}>
                  <Form.Item name={"cashOut"}>
                    {posConfig.cashOut ? <Checkbox style={{ fontWeight: 500 }} checked={cashIn} onChange={onChangeCheckbox}>Cash Out</Checkbox> : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {posConfig.pettyCash === "Y" ? <Form.Item name={"pettyCashOut"}>
                    <Checkbox checked={pettCashIn} style={{ fontWeight: 500 }} onChange={onChangeCheckbox}>Petty cash out</Checkbox>
                  </Form.Item> : null}
                </Col>
              </Row> :
                <Row>
                  <Col span={12}>
                    <Form.Item name={"cashIn"}>
                      {posConfig.cashIn ? <Checkbox style={{ fontWeight: 500 }} checked={cashIn} onChange={onChangeCheckbox}>Cash In</Checkbox> : null}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {posConfig.pettyCash === "Y" ? <Form.Item name={"pettyCashIn"}>
                      <Checkbox checked={pettCashIn} style={{ fontWeight: 500 }} onChange={onChangeCheckbox}>Petty cash In</Checkbox>
                    </Form.Item> : null}
                  </Col>
                </Row>
              }
              <Row>
                <Col span={24}>
                  <Form.Item className="popUpForm" name="amount" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Amount
                    </span>
                  }>
                    <Input
                      placeholder={`e.g, 100`}
                      // prefix={<UserOutlined style={{color:"#929098"}}/>}
                      size="large"
                      allowClear={false}
                      style={data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "6% 0 3% 0" }}>
                  <Form.Item style={{ paddingBottom: 0 }} className="popUpForm" name="note" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Note
                    </span>
                  }>
                    <Input
                      placeholder={`Type to add a note`}
                      size="large"
                      allowClear={false}
                      style={data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput}
                    />
                    <p style={{ marginBottom: "0px", marginTop: "3px", fontWeight: "500", fontSize: "0.8vw", color: "#929098" }}>Max Characters : 200</p>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ paddingBottom: "4%" }}>
                  <Button htmlType="submit" style={{ backgroundColor: '#2F3856', color: '#fff', width: "100%", fontSize: "1.2vw", textAlign: "center", fontWeight: "400", borderRadius: "3px", height: "6vh" }}>
                    Done
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={11}>
              <Row style={{ padding: "0 1% 0 3.5%" }} gutter={[8, 8]}>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("1")}>
                    1
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("2")}>
                    2
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("3")}>
                    3
                  </button>
                  {/* </AspectRatio> */}
                </Col>

                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("4")}>
                    4
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("5")}>
                    5
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("8")}>
                    6
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("7")}>
                    7
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("8")}>
                    8
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("9")}>
                    9
                  </button>
                  {/* </AspectRatio> */}
                </Col>

                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress(".")}>
                    .
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("0")}>
                    0
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                <Col span={8}>
                  {/* <AspectRatio ratio="1"> */}
                  <button className="amt-dial-btn-super-market" onClick={() => handleManualDiscountKeyPress("x")}>
                    <img src={CancelArrow} alt="back space" width={20} height={15} />
                  </button>
                  {/* </AspectRatio> */}
                </Col>
                {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
              </Row>
            </Col>
          </Row>

          <Row>

          </Row>
        </Form>

      </Modal>

      <Drawer
      closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center",height:"2vh" }}>
            <p style={{ fontSize: "2vw",marginTop:"3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>{cashAddInFlag ? "Remove Cash" : "Add Cash"}</p>
            <img src={Close} onClick={()=>setAddCashFlag(false)} style={{ marginLeft: "auto",paddingBottom:"4vh", cursor: "pointer" }} />
          </div>
        }      
       visible={addCashFlag}
        className="filter"
        bodyStyle={{ paddingBottom:0,paddingTop:0 }}
        onClose={()=>setAddCashFlag(false)}
       //  id="sm-drawer-close"
        height="75vh"
      >
         <Form form={cashManagementForm} onFinish={handleCahInOut} layout="vertical">
          <Row style={{padding:"3% 0 0% 0"}}>
            <Col span={1}/>
            <Col span={11} style={{paddingRight:"1vw"}}>
            {cashAddInFlag ? <Row>
              <Col span={2}/>
            <Col span={10}>
              <Form.Item name={"cashOut"}>
              {posConfig.cashOut ? <Checkbox style={{ fontWeight:500 }}  checked={cashIn} onChange={onChangeCheckbox}>Cash Out</Checkbox> : null}
              </Form.Item>
              </Col>
            <Col span={10}>
              {posConfig.pettyCash === "Y" ? 
              <Form.Item name={"pettyCashOut"}>
                <Checkbox checked={pettCashIn} style={{ fontWeight:500 }}  onChange={onChangeCheckbox}>Petty cash out</Checkbox>
              </Form.Item> 
              : null}
            </Col>
            <Col span={2}/>
          </Row> : 
          <Row>
            <Col span={2}/>
          <Col span={10}>
            <Form.Item name={"cashIn"}>
            {posConfig.cashIn ? <Checkbox style={{ fontWeight:500 }} checked={cashIn} onChange={onChangeCheckbox}>Cash In</Checkbox> : null}
            </Form.Item>
            </Col>
          <Col span={10}>
            {posConfig.pettyCash === "Y" ? <Form.Item name={"pettyCashIn"}>
              <Checkbox checked={pettCashIn} style={{ fontWeight:500 }}  onChange={onChangeCheckbox}>Petty cash In</Checkbox>
            </Form.Item> : null}
          </Col>
          <Col span={2}/>
        </Row>
          }
              <Row>
          <Col span={24}>
          <Form.Item
                className="popUpForm"
                name="amount"
                label={<span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}> Amount </span>}
                rules={[
                  {
                    required: true,
                    message: `Please enter amount`,
                  },
                ]}
              >
                <Input
                  placeholder={`e.g, 100`}
                  size="large"// Handle input value changes
                  allowClear={false}
                  style={{ height: "7vh", width: "80%", borderRadius: "4px", border: "1px solid #fff" }}
                />
              </Form.Item>

          </Col>
              <Col span={24} style={{padding:"3% 0 3% 0"}}>
                <Form.Item style={{paddingBottom:0}} className="popUpForm" name="note">
                            <Input
                              placeholder={`Type to add a note`}
                              size="large"
                              onChange={(e) => {handleCashInNote(e.target.value)}}
                              allowClear={false}
                              style={{height:"7vh",width:"80%",borderRadius:"4px",border:"1px solid #fff"}}
                              // style={data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput}
                            />
                <p style={{ marginBottom: "0px", marginTop: "3px" ,fontWeight:"500",fontSize:"0.8vw",color:"#929098"}}>Max Characters : 200</p>
              </Form.Item>
           </Col>
           </Row>
           </Col>
           <Col span={1}/>
           <Col span={11}>
           <Row gutter={[8, 8]} >
              <Col span={5}>
                  <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("1");e.preventDefault()} }>
                    1
                  </button>
                </Col>
                <Col span={5}>
                  <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("2");e.preventDefault()}}>
                    2
                  </button>
                </Col>
                <Col span={5}>
                  <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("3");e.preventDefault()}}>
                    3
                  </button>
                </Col>
                <Col span={5}>
                  <button  className="amt-dial-btn-super-market-cash-management" onClick={(e) => {handleCashManagement("x");e.preventDefault()}}>
                    <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                  </button>
                </Col>
                <Col span={4}/>
                <Col span={5}>
                  <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("4");e.preventDefault()}}>
                    4
                  </button>
                </Col>
                <Col span={5}>
                  <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("5");e.preventDefault()}}>
                    5
                  </button>
                </Col>
                <Col span={5}>
                  <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("6");e.preventDefault()}}>
                    6
                  </button>
                </Col>
                <Col  span={5}>
                  <button  className="amt-dial-btn-super-market-cash-management-cancel-discount" >Clear</button>
                </Col>
                <Col span={4}/>
                <Col span={15}>
                  <Row gutter={[8, 6]}>
                    <Col span={8}>
                      <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("7");e.preventDefault()}}>
                        7
                      </button>
                    </Col>
                    <Col span={8}>
                      <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("8");e.preventDefault()}}>
                        8
                      </button>
                    </Col>
                    <Col span={8}>
                      <button className="amt-dial-btn-super-market-cash-management"   onClick={(e) => {handleCashManagement("9");e.preventDefault()}}>
                        9
                      </button>
                    </Col>
                    <Col span={8}>
                      <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("0");e.preventDefault()}}>
                        0
                      </button>
                    </Col>
                    <Col span={8}>
                      <button  className="amt-dial-btn-super-market-cash-management"  onClick={(e) => {handleCashManagement("00");e.preventDefault()}}>
                        00
                      </button>
                    </Col>
                    <Col span={8}>
                      <button  className="amt-dial-btn-super-market-cash-management" onClick={(e) => {handleCashManagement(".");e.preventDefault()}}>
                        .
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col span={5}>
                  <button
                    // id="sm-amount-buttonEnter"
                    className="amt-dial-btn-market-cash-management-discount"
                  >
                    Enter
                  </button>
                </Col>
                <Col span={4}/>
                {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
            </Row>
              <Row style={{marginTop:"3%" }}>
              <Button htmlType="submit" style={{ backgroundColor: '#2F3856', color: '#fff' ,width:"84%", fontSize:"1.2vw", textAlign:"center", fontWeight:"400",borderRadius:"3px",height:"7vh"}}>
              Done
              </Button>
            </Row>
           </Col>
           
              </Row>
        </Form>
      </Drawer>
      {/* Cash Management Code Modal Ends */}

      {/* Select Gift Card Start */}
      <Modal centered width="50%" bodyStyle={{ padding: "0% 7% 1% 7%", background: "#f3f4f9" }} footer={null} closeIcon={<img src={Close} />} closable={true} onCancel={() => setIsGiftCardFlag(false)}
        title={<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <p style={{ fontSize: "2vw", fontWeight: "500", paddingTop: "2%", paddingLeft: "3%" }}>Select Gift Card</p>
          {/* <img src={Close} /> */}
        </div>}>
        <Scrollbars autoHide className="productScroll1">
              {giftCardItems?.map((obj, i) => (
            <Row gutter={[15, 11]} style={{ width: "99%" ,backgroundColor:"#fff",marginTop:"1.5vh",height: "11vh",marginLeft:"-0.1vw",borderRadius:"10px"}}>
                <Col span={4}>
                <img
                            onError={setDefaultImage}
                            style={{
                              width: "100%",
                              height: "10vh",
                              objectFit: "contain",
                              borderRadius: "5px",
                              paddingTop:"1vh",
                              alignSelf:"center"
                            }}
                            src={obj.imageurl || DefaultProductImage}
                            alt=""
                          />
                </Col>
                <Col span={16}>
                <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                    <label style={{ ...data.posScreenStyles.productsCard.cardDetails.productDescr, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="productName">
                      {obj["description"]}
                    </label>
                  </div>
                 <p style={data.posScreenStyles.productsCard.cardDetails.superMarketProd} className="productName">
                  {obj["name"]}
                  <span style={{display:"block"}}>{obj["value"]}</span>
                  </p>
                </Col>
                <Col span={4} style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                <span onClick={()=>selectGiftCard(obj)} style={{fontSize:"1.5vh",color:"#fff",backgroundColor:"#2F3856",padding:"1vh 1.7vw",marginTop:"2%",borderRadius:"3px",cursor:"pointer"}}>ADD</span>
                </Col>
              <span tabIndex={0}></span>
            </Row>
              ))}

        </Scrollbars>
      </Modal>

      <Drawer
      closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center",height:"2vh" }}>
            <p style={{ fontSize: "2vw",marginTop:"3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Select Gift Card</p>
            <img src={Close} onClick={()=>setIsGiftCardFlag(false)} style={{ marginLeft: "auto",paddingBottom:"2vh", cursor: "pointer" }} />
          </div>
        }      
       visible={isGiftCardFlag}
        className="filter"
        closeIcon={null}
        bodyStyle={{ paddingBottom:0,paddingTop:0 }}
        // onClose={()=>setAddCashFlag(false)}
       //  id="sm-drawer-close"
        height="85vh"
      >
                <Scrollbars autoHide className="productScroll1">
                <Row style={{ marginTop: "1.5vh", marginLeft: "-0.1vw", borderRadius: "10px", padding: "0 3vw" }}>
                {giftCardItems?.map((obj, i) => (
                  <Col push={1} span={7} key={i} style={{ backgroundColor: "#fff", padding: "2vh 2vw", borderRadius: "5px", marginRight: "1vw", marginBottom: "1.5vh" }}>
                    <p style={{ fontWeight: 600, fontSize: "3.5vh", marginBottom: "1.5vh" }} className="productName">
                      {obj["name"]}
                      <span style={{ display: "block", fontSize: "2.5vh", fontWeight: 400 }}>Code: {obj["value"]}</span>
                    </p>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span>Balance: 0</span>
                      <Button onClick={() => selectGiftCard(obj)} style={{ fontSize: "1.7vh", color: "#fff", backgroundColor: "#2F3856", padding: "0.3vh 2.3vw", borderRadius: "3px", cursor: "pointer" }}>ADD</Button>
                    </div>
                  </Col>
                ))}
              </Row>

                
        </Scrollbars>
      </Drawer>
      {/* Select Gift Card End */}

      {/* Validate Gift Card Start */}
      <Drawer
      closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center",height:"2vh" }}>
            <p style={{ fontSize: "2vw",marginTop:"3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>New Gift Card</p>
            <img src={Close} onClick={() => {validateGiftCardForm.resetFields();setValidateGiftCard(false)}} style={{ marginLeft: "auto",paddingBottom:"4vh", cursor: "pointer" }} />
          </div>
        }      
        visible={validateGiftCard}
        className="filter"
        bodyStyle={{ paddingBottom:0,paddingTop:0 }}
        onClose={() => {validateGiftCardForm.resetFields();setValidateGiftCard(false);}}
      //  id="sm-drawer-close"
        height="77vh"
      >
        <Form layout="vertical" form={validateGiftCardForm} onFinish={handleGiftCardDetails}>
        <Row>
        <Col span={11} offset={1} style={{ paddingRight: "1vw" }}>
              <Row>
                <Col span={20} pull={2} push={2}>
                  <Form.Item style={{ marginBottom: "10px" }} name="number" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                     Gift Card Number
                    </span>
                  }>
                    <Input
                      id ="issueCardInput"
                      placeholder={`Enter Number`}
                      onFocus={()=>{setInputFocused("issueGiftCardNumber")}}
                      // prefix={<UserOutlined style={{color:"#929098"}}/>}
                      disabled={Object.keys(selectGiftCardItem).length>0 && selectGiftCardItem?.type === "PHY" ? false : true}
                      size="large"
                      allowClear={false}
                      style={{...data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput,padding:"2vh 1.5vw"}}
                    />
                  </Form.Item>
                </Col>
                <Col span={20} pull={2} push={2} style={{paddingTop:"4vh"}}>
                  <Form.Item style={{ marginBottom: "10px" }} name="amount" initialValue={cart.isReturn ? Math.abs(cart.total) : ""} label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                     Amount
                    </span>
                  }>
                    <Input
                     id ="issueCardAmount"
                     placeholder="Enter Amount"
                     onFocus={()=>{setInputFocused("issueAmount")}}
                      // prefix={<UserOutlined style={{color:"#929098"}}/>}
                      // defaultValue={cart.isReturn ? Math.abs(cart.total) : ""}
                      size="large"
                      allowClear={false}
                      style={{...data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput,padding:"2vh 1.5vw"}}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row >
              <Col span={20} pull={2} push={2} style={{paddingTop:"2vh"}}>
                  <Row gutter={[16,16]}>
                    <Col span={8}>
                      <p onClick={()=>handleAddGiftCardAmount("200")} style={{textAlign:"center",backgroundColor:"#fff",padding:"1vh 0",border:"1px solid rgba(146, 144, 152, 0.5)",borderRadius:"4px"}}>₹200</p>
                    </Col>
                    <Col span={8}>
                      <p onClick={()=>handleAddGiftCardAmount("500")} style={{textAlign:"center",backgroundColor:"#fff",padding:"1vh 0",border:"1px solid rgba(146, 144, 152, 0.5)",borderRadius:"4px"}}>₹500</p>
                    </Col>
                    <Col span={8}>
                      <p onClick={()=>handleAddGiftCardAmount("1000")} style={{textAlign:"center",backgroundColor:"#fff",padding:"1vh 0",border:"1px solid rgba(146, 144, 152, 0.5)",borderRadius:"4px"}}>₹1000</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            
          </Col>
          <Col span={11} offset={1} style={{ padding: "" }}>
            <Row style={{ padding: "0% 1% 0 3.5%" }} gutter={[8, 6]}>
            {/* <Col span={20} pull={2} push={2} style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "10px" }} name="amount" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Enter Amount
                    </span>
                  }>
                    <Input
                     id ="issueCardAmount"
                     onFocus={()=>{setInputFocused("issueAmount")}}
                      // placeholder={`Type to add a note`}
                      size="large"
                      allowClear={false}
                      style={{...data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput,padding:"2.5vh 0"}}
                    />
                  </Form.Item>
                </Col> */}
                <Row style={{ padding: "0 8%" }} gutter={[8, 6]}>
                        <Col span={6}>
                          <button id="sm-amount-button1" className="amt-dial-btn-gift1" onClick={(e) =>{ handleAddGiftCardAmount("1");e.preventDefault();}}>
                            1
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button2" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("2");e.preventDefault()}}>
                            2
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button3" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("3");e.preventDefault()}}>
                            3
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-gift1" id="sm-amount-buttonRemove" onClick={(e) => {handleAddGiftCardAmount("x");e.preventDefault()}}>
                            <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button4" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("4");e.preventDefault()}}>
                            4
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button5" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("5");e.preventDefault()}}>
                            5
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button6" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("6");e.preventDefault()}}>
                            6
                          </button>
                        </Col>
                        <Col span={6}>
                            <button
                            onClick={(e) => {handleAddGiftCardAmount("clear");e.preventDefault()}}
                              id="sm-amount-addQuantity" className="amt-dial-btn-gift1">
                              Clear
                            </button>
                            
                        </Col>
                        <Col span={18}>
                          <Row gutter={[8, 6]}>
                            <Col span={8}>
                              <button id="sm-amount-button7" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("7");e.preventDefault()}}>
                                7
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button8" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("8");e.preventDefault()}}>
                                8
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("9");e.preventDefault()}}>
                                9
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("0");e.preventDefault()}}>
                                0
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button00" className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount(".");e.preventDefault()}}>
                                .
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button." className="amt-dial-btn-gift1" onClick={(e) => {handleAddGiftCardAmount("00");e.preventDefault()}}>
                                00
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-buttonEnter" className="amt-dial-btn-gift12" >
                            Enter
                          </button>
                        </Col>
                 </Row>
              <Col span={20} pull={2} push={2} style={{ padding: "3% 0" }}>
                  <Button htmlType="submit" style={{ backgroundColor: '#2F3856', color: '#fff', width: "100%", fontSize: "1.2vw", textAlign: "center", fontWeight: "400", borderRadius: "7px", height: "8vh" }}>
                    Issue
                  </Button>
                </Col>
              {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
            </Row>
          </Col>
          
        </Row>
        </Form>
      </Drawer>
      {/* Validate Gift Card End */}

      {/* Gift Vocher Code Modal Starts */}
      <Modal  centered width="50%" bodyStyle={{ padding: "0% 7% 1% 7%", background: "#f3f4f9" }} footer={null} closeIcon={<img src={Close} />} closable={true} onCancel={() => setGiftCardFlag(false)}
        title={<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <p style={{ fontSize: "2vw", fontWeight: "500", paddingTop: "2%", paddingLeft: "3%" }}>Gift Card</p>
          {/* <img src={Close} /> */}
        </div>}>

        <Row style={{ padding: "0 0 6% 0" }}>
          <Col span={13} style={{ paddingRight: "1vw" }}>
            <Form layout="vertical">
              <Row>
                <Col span={24}>
                  <Form.Item style={{ marginBottom: "10px" }} name="amount" label={
                    <span style={{ color: "#000", fontSize: "1vw", fontWeight: "500" }}>
                      Gift Card Number
                    </span>
                  }>
                    <Input
                      placeholder={`Enter Number`}
                      // prefix={<UserOutlined style={{color:"#929098"}}/>}
                      size="large"
                      allowClear={false}
                      style={data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "10px" }} name="note" label={
                    <span style={{ color: "#000", fontSize: "1vw", fontWeight: "500" }}>
                      PIN
                    </span>
                  }>
                    <Input
                      placeholder={`Enter Pin`}
                      size="large"
                      allowClear={false}
                      style={data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "3% 0" }}>
                  <Button style={{ backgroundColor: '#2F3856', color: '#fff', width: "100%", fontSize: "0.8vw", textAlign: "center", fontWeight: "400", borderRadius: "5px", height: "4vh", width: "40%" }}>
                    Check Gift Card
                  </Button>
                </Col>
                <Col span={24} style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "10px" }} name="note" label={
                    <span style={{ color: "#000", fontSize: "1vw", fontWeight: "500" }}>
                      Balance
                    </span>
                  }>
                    <Input
                      // placeholder={``}
                      size="large"
                      allowClear={false}
                      style={data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "10px" }} name="note" label={
                    <span style={{ color: "#000", fontSize: "1vw", fontWeight: "500" }}>
                      Redeem Amount
                    </span>
                  }>
                    <Input
                      // placeholder={`Type to add a note`}
                      size="large"
                      allowClear={false}
                      style={data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ padding: "3% 0" }}>
                  <Button htmlType="submit" style={{ backgroundColor: '#2F3856', color: '#fff', width: "100%", fontSize: "1.2vw", textAlign: "center", fontWeight: "400", borderRadius: "7px", height: "6vh" }}>
                    Redeem
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col span={11} style={{ padding: "3% 0" }}>
            <Row style={{ padding: "3.5% 1% 0 3.5%" }} gutter={[8, 6]}>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("1")}>
                  1
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("2")}>
                  2
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("3")}>
                  3
                </button>
                {/* </AspectRatio> */}
              </Col>

              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("4")}>
                  4
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("5")}>
                  5
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("8")}>
                  6
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("7")}>
                  7
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("8")}>
                  8
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("9")}>
                  9
                </button>
                {/* </AspectRatio> */}
              </Col>

              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput(".")}>
                  .
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("0")}>
                  0
                </button>
                {/* </AspectRatio> */}
              </Col>
              <Col span={8}>
                {/* <AspectRatio ratio="1"> */}
                <button className="amt-dial-btn-super-market" onClick={() => handleCashInOutInput("x")}>
                  <img src={CancelArrow} alt="back space" width={20} height={15} />
                </button>
                {/* </AspectRatio> */}
              </Col>
              {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
            </Row>
          </Col>
        </Row>
      </Modal>
      
      <Drawer
      closable={true}
        placement="bottom"
        title={
          <div style={{ display: "flex", alignItems: "center",height:"2vh" }}>
            <p style={{ fontSize: "2vw",marginTop:"3vh", fontWeight: "500", color: "#2F3856", flex: 1, textAlign: "center" }}>Redeem Gift Card</p>
            <img src={Close} onClick={() => {giftCardForm.resetFields();setGiftCardFlag(false); setGiftCardBalance(0.00)}} style={{ marginLeft: "auto",paddingBottom:"4vh", cursor: "pointer" }} />
          </div>
        }      
        visible={giftCardFlag}
        className="filter"
        bodyStyle={{ paddingBottom:0,paddingTop:0 }}
        onClose={() => {setGiftCardFlag(false);giftCardForm.resetFields();setGiftCardBalance(0.00)}}
      //  id="sm-drawer-close"
        height="87vh"
      >
         <Form layout="vertical" onFinish={redeemGiftCard} form={giftCardForm}>
        <Row>
        <Col span={11} offset={1} style={{ paddingRight: "1vw" }}>
              <Row>
                <Col span={20} pull={2} push={2}>
                  <Form.Item style={{ marginBottom: "10px" }} name="number" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Gift Card Number
                    </span>
                  }>
                    <Input
                      id="redeemNumber"
                      placeholder={`Enter Number`}
                      onFocus={()=>{setInputFocused("redeemNumber")}}
                      // prefix={<UserOutlined style={{color:"#929098"}}/>}
                      size="large"
                      onPressEnter={(e)=> e.preventDefault()}
                      allowClear={false}
                      style={{...data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput,padding:"2vh 1vw"}}
                    />
                  </Form.Item>
                </Col>
                <Col span={20} pull={2} push={2} style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "10px" }} name="pin" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      PIN
                    </span>
                  }>
                    <Input
                     id="pin"
                      onFocus={()=>{setInputFocused("pin")}}
                      placeholder={`  Enter Pin`}
                      size="large"
                      onPressEnter={(e)=> e.preventDefault()}
                      allowClear={false}
                      style={{...data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput,padding:"2vh 1vw"}}
                    />
                  </Form.Item>
                </Col>
                <Col span={20} pull={2} push={2} style={{ padding: "1% 0" }}>
                  <Button style={{ backgroundColor: '#046307C2', color: '#fff', width: "100%", fontSize: "1.2vw", textAlign: "center", fontWeight: "500", borderRadius: "5px", height: "8vh", width: "40%" }} onClick={handleGiftCard}>
                    Check Balance
                  </Button>
                </Col>
                <Col span={8} push={2}  style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "" }} name="amount" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Gift Card Balance
                    </span>
                  }>
                   <span style={{fontSize:"2.5rem",fontWeight:500,lineHeight:"2rem"}}>{parseFloat(giftCardBalance).toFixed(2)}</span>
                  </Form.Item>
                </Col>
                <Col span={8} offset={4} push={2} style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "" }} name="amount" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Bill Amount
                    </span>
                  }>
                   <span style={{fontSize:"2.5rem",fontWeight:500,lineHeight:"2rem"}}>{cart.total.toFixed(2)}</span>
                  </Form.Item>
                </Col>
                <Col span={8}  push={2} style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "" }} name="amount" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Redeem Amount
                    </span>
                  }>
                   <span style={{fontSize:"2.5rem",fontWeight:500,lineHeight:"2rem"}}>{cart.payments.find(item => item.isGiftCard)?.amount ?? "0.00"}</span>
                  </Form.Item>
                </Col>
                <Col span={8} offset={4} push={2}style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "" }} name="amount" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Remaining Due
                    </span>
                  }>
                   <span style={{fontSize:"2.5rem",fontWeight:500,lineHeight:"2rem"}}>{amount}</span>
                  </Form.Item>
                </Col>
              </Row>
            
          </Col>
          <Col span={11} offset={1} style={{ padding: "" }}>
            <Row style={{ padding: "0% 1% 0 3.5%" }} gutter={[8, 6]}>
            <Col span={20} pull={2} push={2} style={{ padding: "" }}>
                  <Form.Item style={{ marginBottom: "10px" }} name="amount" label={
                    <span style={{ color: "#000", fontSize: "1.2vw", fontWeight: "500" }}>
                      Redeem Amount
                    </span>
                  }>
                    <Input
                    id="amountNumber"
                    onFocus={()=>{setInputFocused("amount")}}
                      // placeholder={`Type to add a note`}
                      size="large"
                      onPressEnter={(e)=> e.preventDefault()}
                      allowClear={false}
                      style={{...data.posScreenStyles.orderHistoryModal.cashManagmentSearchInput,padding:"2.5vh 1vw",fontSize:600,fontSize:"1.5vw"}}
                    />
                  </Form.Item>
                </Col>
                <Row style={{ padding: "0 8%" }} gutter={[8, 6]}>
                        <Col span={6}>
                          <button id="sm-amount-button1" className="amt-dial-btn-gift" onClick={(e) =>{ handleAddGiftCardAmount("1");e.preventDefault();}}>
                            1
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button2" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("2");e.preventDefault()}}>
                            2
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button3" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("3");e.preventDefault()}}>
                            3
                          </button>
                        </Col>
                        <Col span={6}>
                          <button className="amt-dial-btn-gift" id="sm-amount-buttonRemove" onClick={(e) => {handleAddGiftCardAmount("x");e.preventDefault()}}>
                            <img src={CancelArrow} alt="back space" width={width <= 1024 ? 20 : 25} height={width <= 1024 ? 15 : 15} />
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button4" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("4");e.preventDefault()}}>
                            4
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button5" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("5");e.preventDefault()}}>
                            5
                          </button>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-button6" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("6");e.preventDefault()}}>
                            6
                          </button>
                        </Col>
                        <Col span={6}>
                         
                            <button
                            onClick={(e) => {handleAddGiftCardAmount("clear");e.preventDefault()}}
                              id="sm-amount-addQuantity" className="amt-dial-btn-gift">
                              Clear
                            </button>
                            
                        </Col>
                        <Col span={18}>
                          <Row gutter={[8, 6]}>
                            <Col span={8}>
                              <button id="sm-amount-button7" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("7");e.preventDefault()}}>
                                7
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button8" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("8");e.preventDefault()}}>
                                8
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("9");e.preventDefault()}}>
                                9
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button0" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("0");e.preventDefault()}}>
                                0
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button00" className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount(".");e.preventDefault()}}>
                                .
                              </button>
                            </Col>
                            <Col span={8}>
                              <button id="sm-amount-button." className="amt-dial-btn-gift" onClick={(e) => {handleAddGiftCardAmount("00");e.preventDefault()}}>
                                00
                              </button>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6}>
                          <button id="sm-amount-buttonEnter" className="amt-dial-btn-gift2" >
                            Enter
                          </button>
                        </Col>
                 </Row>
              <Col span={20} pull={2} push={2} style={{ padding: "3% 0",marginTop:"1%" }}>
                  <Button  disabled={!giftCardForm.getFieldValue('number')} htmlType="submit" style={{ backgroundColor: '#2F3856', color: '#fff', width: "100%", fontSize: "1.2vw", textAlign: "center", fontWeight: "400", borderRadius: "7px", height: "8vh" }}>
                    Redeem
                  </Button>
                </Col>
              {/* <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={applyManualDiscount}>
                  <img src={Tick} alt="" />
                </Button> */}
            </Row>
          </Col>
          
        </Row>
        </Form>
      </Drawer>
      {/* <Modal className="voucher" visible={false} centered width="50%" bodyStyle={{padding:"1% 7%",background:"#fff"}} footer={null} closeIcon={<img src={Close}/>} closable={true} onCancel={()=>setAddCashFlag(false)} 
      title={<div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",clipPath:"polygon(0 0, 100% 0, 100% 100%, 0 80%)",height:"31vh"}}>
      <p style={{fontSize:"2vw", fontWeight:"500",paddingTop:"2%",paddingLeft:"3%",marginBottom:"17vh"}}>CONGRATULATIONS !</p>
      </div>}>
      <div style={{position:"relative",display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center"}}>
      <img src={Voucher} style={{height:"20vh",position:"absolute",top:"-65%",left:"35%"}} />
     <p style={{fontFamily:"Inter",fontWeight:500,fontSize:"2.5vw",textAlign:"center",margin:"0"}}>You get extra discount </p>
     <p style={{fontFamily:"Inter",fontWeight:500,fontSize:"4vw",textAlign:"center",margin:"0"}}>₹300 </p>
     <p style={{fontFamily:"Inter",fontWeight:400,fontSize:"1.2vw",textAlign:"center",margin:"0"}}>Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.  </p>
     <Button style={{width:"70%",background:"#2F3856",borderRadius:"15px",height:'6vh',margin:"2vh 0",color:"#fff",fontFamily:"Inter",fontWeight:500 }} >
         REDEEM NOW
      </Button>  
      </div>
           
      </Modal> */}
      {/* Gift Vocher Code Modal Ends */}
    </Fragment>
  );
};

export default PointOfSaleRetailModals;
