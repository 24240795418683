import Axios from "axios";
import db from "../../database";
import openDrawer from "./openDrawer";


const TillCloseHCPrint = async (printReciptData, cashValues, x, cashToKeep) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let printData = tillData.tillAccess.cwrTill.tillCloseTemplate.xmlcode2;
  let ordersData = await db.ordersData.toArray();
  ordersData = ordersData.filter(data => data.tillStatus === "open");
  let paymentsData = await db.paymentsData.toArray();
  paymentsData = paymentsData.filter(data => data.tillStatus === "open");
  let tillEvents = await db.tillEvents.toArray();
  tillEvents = tillEvents.filter(data => data.tillStatus === "open");
  let countedAmountsData = "";
  let toDepositAmount = "";
  let differenceAmountsData = "";
  let differenceAmountsDataTotal = 0;
  let totalDeposistsAmount = 0;
  let totalWithDrawalsAmount = 0;
  let TotalcountedAmount = 0;
  let totalToDepositAmount = 0;
  let startingPaymentsAmount = '';
  let taxDetails = '';
  let taxReturnDetails = '';
  let withdrawalsAmount = '';
  let depositsAmount = '';
  let totalExpectedAmount = '';
  let expectedAmountsData = "";
  tillEvents[0]?.allPaymentsData?.map((data) => {
    return (expectedAmountsData += `<line>
    <text align ="left" length="25" >${data.name}</text>
    <text align ="right" length="14" >${data.expectedAmount ? parseFloat(data.expectedAmount).toFixed(2) : '0.00'}</text>
  </line>`,
      totalExpectedAmount = tillEvents[0]?.allPaymentsData?.reduce((total, data) => total + parseFloat(data?.expectedAmount), 0).toFixed(2),
      startingPaymentsAmount += `
  <line>
    <text align ="left" length="25" >${data.name}</text>
    <text align ="right" length="14" >${data.name === "Cash" ? tillEvents[0]?.openingCash ? parseFloat(tillEvents[0]?.openingCash).toFixed(2) : '0.00' : "0.00"}</text>
  </line>
  `,
      withdrawalsAmount += `
  <line>
    <text align ="left" length="25" >${data.name}</text>
    <text align="right" length="14">${data.name === "Cash"
        ? (parseFloat(data.paymentReturn) + (
          tillEvents[0]?.cashInOutData
            ? (parseFloat(tillEvents[0]?.cashInOutData?.cashOut || 0) +
              parseFloat(tillEvents[0]?.cashInOutData?.pettCashOut || 0))
            : 0)).toFixed(2)
        : parseFloat(data.paymentReturn).toFixed(2)}
</text>

    </line>
  `,
      totalWithDrawalsAmount = 
        (tillEvents[0]?.allPaymentsData?.reduce((total, data) => total + parseFloat(data.paymentReturn), 0) || 0),
      depositsAmount += `
  <line>
    <text align ="left" length="25" >${data.name}</text>
    <text align="right" length="14">${data.name === "Cash" ? (
        parseFloat(data.cashSaleAmount || 0) +
        (tillEvents[0]?.cashInOutData ? (
          parseFloat(tillEvents[0]?.cashInOutData?.cashIn || 0) +
          parseFloat(tillEvents[0]?.cashInOutData?.pettCashIn || 0)
        ) : 0)
      ).toFixed(2) : parseFloat(data.expectedAmount || 0).toFixed(2)}</text>
  
    </line>
  `,
      totalDeposistsAmount = 
        (tillEvents[0]?.allPaymentsData?.reduce((total, data) =>
          total + (data.cashSaleAmount || data.expectedAmount), 0) || 0),

      countedAmountsData += `
  <line>
    <text align ="left" length="25" >${data.name}</text>
    <text align ="right" length="14" >${data?.amount ? parseFloat(data.amount).toFixed(2) : '0.00'}</text>
  </line>
  `,
      TotalcountedAmount = tillEvents[0]?.allPaymentsData?.reduce((total, data) => total + parseFloat(data.amount), 0).toFixed(2),
      toDepositAmount += `
      <line>
        <text align ="left" length="25" >${data.name}</text>
        <text align="right" length="14">${data.name === "Cash" ? (
        parseFloat(data.amount || 0) -
        Number(cashToKeep)
      ).toFixed(2) : parseFloat(data.amount || 0).toFixed(2)}</text>
      </line>
      `,
      totalToDepositAmount = tillEvents[0]?.allPaymentsData?.reduce((total, data) => total + parseFloat(data.amount), 0).toFixed(2),
      differenceAmountsData += `<line>
    <text align ="left" length="25" >${data.name}</text>
    <text align ="right" length="14" >${data.difference ? parseFloat(data.difference).toFixed(2) : '0.00'}</text>
  </line>`,
      differenceAmountsDataTotal = tillEvents[0]?.allPaymentsData?.reduce((total, data) => total + parseFloat(data.difference), 0).toFixed(2)
    );
  });
  totalWithDrawalsAmount = (parseFloat(totalWithDrawalsAmount) || 0) +
    (parseFloat(tillEvents[0]?.cashInOutData?.cashOut || 0) +
      parseFloat(tillEvents[0]?.cashInOutData?.pettCashOut || 0));

  totalDeposistsAmount = (parseFloat(totalDeposistsAmount) || 0) +
    (parseFloat(tillEvents[0]?.cashInOutData?.cashIn || 0) +
      parseFloat(tillEvents[0]?.cashInOutData?.pettCashIn || 0));

  totalWithDrawalsAmount = parseFloat(totalWithDrawalsAmount).toFixed(2);
  totalDeposistsAmount = parseFloat(totalDeposistsAmount).toFixed(2);


  totalToDepositAmount = (parseFloat(totalToDepositAmount) - Number(cashToKeep)).toFixed(2)
  const groupedByVatPercentage = {};
  ordersData[0]?.salesTax?.forEach((vat) => {
    const vatPercentage = vat.taxRate;
    if (!groupedByVatPercentage[vatPercentage]) {
      groupedByVatPercentage[vatPercentage] = {
        vatPercentage: vatPercentage,
        totalVatAmount: 0,
      };
    }

    groupedByVatPercentage[vatPercentage].totalVatAmount += parseFloat(vat.taxableAmt);
  });

  Object.values(groupedByVatPercentage).forEach((group) => {
    taxDetails += `
      <line>
        <text align="left" length="25">VAT ${group.vatPercentage}%</text>
        <text align="right" length="14">${group.totalVatAmount.toFixed(2)}</text>
      </line>`;
  });
  const groupedReturnByVatPercentage = {};

  ordersData[0]?.returnTax?.forEach((vat) => {
    const vatPercentage = vat.taxRate;
    if (!groupedReturnByVatPercentage[vatPercentage]) {
      groupedReturnByVatPercentage[vatPercentage] = {
        vatPercentage: vatPercentage,
        totalVatAmount: 0,
      };
    }

    groupedReturnByVatPercentage[vatPercentage].totalVatAmount += parseFloat(vat.taxableAmt);
  });

  Object.values(groupedReturnByVatPercentage).forEach((group) => {
    taxReturnDetails += `
      <line>
        <text align="left" length="25">VAT ${group.vatPercentage}%</text>
        <text align="right" length="14">${Math.abs(group.totalVatAmount).toFixed(2)}</text>
      </line>`;
  });

  let toKeepAmount = `
  <line>
    <text align ="left" length="25" >Card</text>
    <text align ="right" length="14" >0.00</text>
  </line>
  <line>
    <text align ="left" length="25" >Cash</text>
    <text align ="right" length="14" >${Number(cashToKeep).toFixed(2)}</text>
  </line>`
  printData = printData.replaceAll("@@status@@", `Closed`);
  printData = printData.replaceAll("@@storeName@@", `${tillData.tillAccess.csBunit.name}`);
  printData = printData.replaceAll("@@tillID@@", `${tillData.tillAccess.cwrTill.till}`);
  printData = printData.replaceAll("@@user@@", `${localStorage.getItem("username")}`);
  printData = printData.replaceAll("@@openingTime@@", `${printReciptData?.openingTime}`);
  printData = printData.replaceAll("@@closingTime@@", `${printReciptData?.closingTime}`);
  printData = printData.replaceAll("@@netSalesAmount@@", `${parseFloat(ordersData[0]?.netTotal).toFixed(2)}`);
  printData = printData.replaceAll("@@netReturnAmount@@", `${Math.abs(parseFloat(ordersData[0]?.netReturnTotal)).toFixed(2)}`);
  printData = printData.replaceAll("@@grossSalesAmount@@", `${parseFloat(ordersData[0]?.grossTotal).toFixed(2)}`);
  printData = printData.replaceAll("@@grossReturnAmount@@", `${Math.abs(parseFloat(ordersData[0]?.grossReturnTotal)).toFixed(2)}`);
  printData = printData.replaceAll("@@retailTransactions@@", `${(parseFloat(ordersData[0]?.grossTotal) + parseFloat(ordersData[0]?.grossReturnTotal))?.toFixed(2)}`);
  printData = printData.replaceAll("@@totalStartingAmount@@", `${tillEvents[0]?.openingCash ? parseFloat(tillEvents[0]?.openingCash).toFixed(2) : '0.00'}`);
  printData = printData.replaceAll("@@totalDeposistsAmount@@", `${totalDeposistsAmount}`);
  printData = printData.replaceAll("@@countedAmount@@", `${countedAmountsData}`);
  printData = printData.replaceAll("@@totalCountedAmount@@", `${TotalcountedAmount ? TotalcountedAmount : "0.00"}`);
  printData = printData.replaceAll("@@expectedAmount@@", `${expectedAmountsData}`);
  printData = printData.replaceAll("@@totalExpectedAmount@@", `${totalExpectedAmount ? totalExpectedAmount : "0.00"}`);
  printData = printData.replaceAll("@@differenceAmount@@", `${differenceAmountsData}`);
  printData = printData.replaceAll("@@totalDifferenceAmount@@", `${differenceAmountsDataTotal ? differenceAmountsDataTotal : "0.00"}`);
  printData = printData.replaceAll("@@totalToDepositAmount@@", `${totalToDepositAmount ? totalToDepositAmount : "0.00"}`);
  printData = printData.replaceAll("@@totalWithDrawalsAmount@@", `${totalWithDrawalsAmount ? totalWithDrawalsAmount : "0.00"}`);
  printData = printData.replaceAll("@@startingPaymentsAmount@@", `${startingPaymentsAmount}`);
  printData = printData.replaceAll("@@taxDetails@@", `${taxDetails}`);
  printData = printData.replaceAll("@@taxReturnDetails@@", `${taxReturnDetails}`);
  printData = printData.replaceAll("@@toKeepAmount@@", `${toKeepAmount}`);
  printData = printData.replaceAll("@@totalToKeepAmount@@", `${Number(cashToKeep).toFixed(2)}`);
  printData = printData.replaceAll("@@toDepositAmount@@", `${toDepositAmount ? toDepositAmount : "0.00"}`);
  printData = printData.replaceAll("@@withdrawalsAmount@@", `${withdrawalsAmount}`);
  printData = printData.replaceAll("@@depositsAmount@@", `${depositsAmount}`);

  Axios
    .post(`${printerURL}printer`, printData, {
      headers: {
        'Content-Type': 'application/xml; charset=UTF-8',
        'Accept': 'application/xml'
      }
    })
    .then(() => {
      console.log("XML print success !");
      openDrawer()

    })
    .catch((response) => {
      console.log("XML print failed !", response);
    });
}
export default TillCloseHCPrint;