export const priceAdjustment = async (product,pricingRule,setCart,cart,orderType,cartObj,iscoupon,couponInput,uniqReferenceId,mPricingCouponId,mPricingRulesId,removeDiscounts) => {
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;

  // Check if there are any changes
  let hasChanges = false;

  // Iterate through each item in the cart
  cartObj.items.map((item) => {
    let excludingFlag = true;

    const matchingCategory = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === item.mProductCategoryId);
    if (
      (pricingRule.excludeProductCategories === "Y" && matchingCategory) ||
      (pricingRule.excludeProductCategories !== "Y" && !matchingCategory) ||
      (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === item.productBrandId);
    if (
      (pricingRule.excludeBrands === "Y" && matchingBrand) ||
      (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
      (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === item.productId);
    if (
      (pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||
      (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||
      (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
    ) {
      excludingFlag = false;
    }

    let pricingRuleFlag = true;

    if(item.discount <= 0){
      delete item.priority;
    }

    if (saleTypeValidation && excludingFlag && pricingRuleFlag && (product.productId === item.productId || iscoupon || removeDiscounts) && (item?.priority ? item?.priority < pricingRule.priority : true)) {
      const index = cartObj.items.findIndex((p) => p.productId === item.productId && p.upc === item.upc && p.mBatchId === item.mBatchId);
      pricingRule.minimumQty = pricingRule.minimumQty ? pricingRule.minimumQty : 0;

      if (index >= 0) {
        if (Math.abs(cartObj.items[index].weight) >= pricingRule.minimumQty) {
          const discountAmt = pricingRule.fixedUnitPrice;
          const sp = pricingRule.fixedUnitPrice;
          const mrp = parseFloat(sp) * cartObj.items[index].weight;
          const tax = mrp - mrp / (1 + cartObj.items[index].taxRate / 100);

          // Check if any changes are made
          if (
            sp !== cartObj.items[index].salePrice ||
            tax !== cartObj.items[index].taxAmount ||
            mrp !== cartObj.items[index].nettotal ||
            discountAmt !== cartObj.items[index].discount
          ) {
            if (cartObj.items[index].productId === product.productId) {
              hasChanges = true;
            }
          }

          cartObj.items[index].salePrice = sp;
          cartObj.items[index].taxAmount = tax;
          cartObj.items[index].nettotal = mrp;
          cartObj.items[index].discount = parseFloat(Math.abs(cartObj.items[index].realPrice) * Math.abs(cartObj.items[index].weight)) - Math.abs(discountAmt * cartObj.items[index].weight);
          cartObj.items[index].discountName = pricingRule.printedName;
          cartObj.items[index].mPricingruleId = pricingRule.mPricingrulesId;
          cartObj.items[index].nextRule = pricingRule.nextRule;
          cartObj.items[index].iscoupon = iscoupon;
          cartObj.items[index].couponInput = couponInput;
          cartObj.items[index].referenceId = uniqReferenceId;
          cartObj.items[index].priority = pricingRule.priority;
        } else {
          const mrp = parseFloat(cartObj.items[index].realPrice) * cartObj.items[index].weight;
          const tax = mrp - mrp / (1 + cartObj.items[index].taxRate / 100);

          // Check if any changes are made
          if (tax !== cartObj.items[index].taxAmount || mrp !== cartObj.items[index].nettotal || cartObj.items[index].discount !== 0 || cartObj.items[index].discountName !== "") {
            if (cartObj.items[index].productId === product.productId) {
              hasChanges = true;
            }
          }

          cartObj.items[index].taxAmount = tax;
          cartObj.items[index].nettotal = mrp;
          cartObj.items[index].discount = 0;
          cartObj.items[index].discountName = "";
          cartObj.items[index].mPricingruleId = null;
          delete cartObj.items[index].nextRule;
        }
      }
    }
  });

  // Check if any changes are made and return the updated cart object
  if (hasChanges) {
    let couponsData = cart.couponInput?.length > 0 ? [...cart.couponInput] : [];
    if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null) {
      couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: mPricingRulesId});
    }
    cartObj.iscoupon = iscoupon;
    cartObj.couponInput = couponsData;
    setCart(cartObj);
    localStorage.setItem("cartObj", JSON.stringify(cartObj));
    return cartObj;
  }
};
