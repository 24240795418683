export const PackageDiscount = async (product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletypeId;
  let excludingFlag = true;

  const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);

  if (
    (pricingRule.excludeProductCategories === "Y" && matchingCategoy) ||
    (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy) ||
    (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
  ) {
    excludingFlag = false;
  }

  const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);

  if (
    (pricingRule.excludeBrands === "Y" && matchingBrand) ||
    (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
    (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
  ) {
    excludingFlag = false;
  }

  const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);

  if (
    (pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||
    (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||
    (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
  ) {
    excludingFlag = false;
  }

  let pricingRuleFlag = true;
  if (pricingRule.mPricingXProducts.length > 0) {
    pricingRule.mPricingXProducts.some((op) => {
      cartObj.items.map((product) => {
        if (op.mProductId === product.productId && product.nextRule === "Y") {
          pricingRuleFlag = false;
        }
      });
    });
  } else {
    if (product.nextRule === "Y") {
      pricingRuleFlag = false;
    }
  }

  if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
    const addedToCart = cartObj.items;
    const offerProducts = pricingRule.mPricingXProducts;
    const prodIndexes = [];
    const properWeights = [];
    let productsPriceTotal = 0;
    let modificationFlag = false;

    addedToCart.forEach((item, index) => {
      if(item.discount <= 0){
        delete item.priority;
      }  
      const ifExists = offerProducts.findIndex((op) => op.mProductId === item.productId && (item?.priority ? item?.priority <= pricingRule.priority : true));
      if (ifExists >= 0 && offerProducts[index]?.quantity <= item?.weight) {
        prodIndexes.push(index);
        properWeights.push(item.weight);
        productsPriceTotal += parseFloat(item.realPrice);
      }
    });

    if (properWeights.every((item) => item === properWeights[0])) {
      prodIndexes.forEach((index) => {
        let distributedDiscount = pricingRule.fixedUnitPrice;
        const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        const indexItem = offerProducts.findIndex((op) => op.mProductId === addedToCart[index].productId);
        let modValue = Math.floor(addedToCart[index].weight / offerProducts[indexItem].quantity);
        let flagCheck = pricingRule.fixedUnitPrice <= Math.abs(addedToCart[index].realPrice * offerProducts[indexItem].quantity);
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = flagCheck
          ? mrp - (offerProducts[indexItem].quantity * modValue * addedToCart[index].realPrice - Math.abs(distributedDiscount) * modValue)
          : mrp;
        addedToCart[index].discount = flagCheck
          ? offerProducts[indexItem].quantity * modValue * Math.abs(addedToCart[index].realPrice) - Math.abs(distributedDiscount) * modValue
          : 0;
        addedToCart[index].discountName = modValue >= 1 && flagCheck ? pricingRule.printedName : "";
        addedToCart[index].mPricingruleId = flagCheck ? pricingRule.mPricingrulesId : null;
        addedToCart[index].priority = flagCheck ? pricingRule.priority : null;
        modificationFlag = flagCheck ? true : false;
      });
    } else {
      prodIndexes.forEach((index) => {
        const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        addedToCart[index].discount = 0;
        addedToCart[index].discountName = "";
        addedToCart[index].mPricingruleId = null;
        modificationFlag = true;
      });
    }
    if(modificationFlag){
      let totalTax = 0;
      let totalPrice = 0;
      let totalItemsQty = 0;
      let totalDiscounts = 0;
  
      addedToCart.forEach((item) => {
        totalPrice += item.nettotal;
        totalItemsQty += item.weight;
        totalTax += item.taxAmount;
        totalDiscounts += item.discount;
      });
  
      const roundOffValue = Math.round(totalPrice);
      const totalRoundOff = totalPrice - roundOffValue;
      totalPrice = roundOffValue;
      let couponsData = cart.couponInput?.length > 0 ? [...cart.couponInput] : [];
      if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null) {
        couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: mPricingRulesId });
      }
      const finalObj = {
        ...cartObj,
        couponInput: couponsData,
        items: [...addedToCart],
        total: totalPrice,
        tax: totalTax,
        discount: totalDiscounts,
        totalQty: totalItemsQty,
        roundOff: totalRoundOff,
      };
      localStorage.setItem("cartObj", JSON.stringify(finalObj));
      setCart(finalObj);
      return finalObj;
    }
  }
};
