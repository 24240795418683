import React from "react";
import Axios from "axios";
import { Card, Row, Col, Image, Button, Spin, Modal, message } from "antd";
import first1 from "../../assets/images/first1.svg";
import logo from "../../assets/images/NewLogoCW.svg";
import red from "../../assets/images/red.svg";
import green from "../../assets/images/green.svg";
import posImg from "../../assets/images/posImg.svg";
import laptop from "../../assets/images/tillRegistation.png";
import db from "../../database";
import moment from "moment";
import { timeStamp } from "../../utility/timestamp";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import { useHistory } from "react-router-dom";

const Till = () => {
  const appUrl = process.env.REACT_APP_AppsUrl;
  const serverUrl = process.env.REACT_APP_serverUrl;
  const redirectUrl = process.env.REACT_APP_redirectURL;
  const SSOURL = process.env.REACT_APP_SSOURL;
  const logoutUrl = process.env.REACT_APP_logoutUrl;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tills, setTills] = useState();
  const tillValue = JSON.parse(localStorage.getItem("tillValue"));
  const posConfigData = JSON.parse(localStorage.getItem("posConfig"));
  const islocked = localStorage.getItem("locked");
  let email = "";
  let Enterprise = "";
  let username;
  let cleanToken;
  const authHeaders = getOAuthHeaders();
  if (authHeaders && authHeaders.access_token) {
    email = authHeaders.email;
    Enterprise = authHeaders.Enterprise;
    cleanToken = authHeaders.access_token;
    username = authHeaders.username;
  } else {
    window.location.assign(`${SSOURL}sign-in/?&redirect_uri=${redirectUrl}`);
  }

  useEffect(async () => {
    // console.log(authHeaders,tillValue)
    const nameTill = tillValue?.name;
    if (tillValue && (authHeaders.username || authHeaders.email) && authHeaders?.access_token) {
      const localTill = JSON.parse(localStorage.getItem("tillData"))
      setLoading(true);
      const resData = await Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `query{
            tillData(user:"${Enterprise ? username : email}"){   
               
              posScanConfigs{
                  cwrPosScanConfigId
                  scanTrxType
                  dataType
                  barcodeLength
                  startsWith
                  endsWith
                  customFormula
                  formula
              }
              loyaltyApply{
                  cwrLoyaltyLevelId
                  name
                  applicableFor
                  prodCategories{
                      mProductCategoryId
                      include
                      }
                  }  
              tillAccess{
                cwrTillaccessId
                csClientId
                csUserId
                userAccessController
                cashManagement
              productSync
            salesHistory
            giftCard
            manualDiscount
            salesReport
            parkedBills
            layAway
            unlinkTill
            holdBill
            couponCode
              tillAccessMeta{
                  key
                  value
              }
                cwrTill{
                   cwrTillID
                   searchKey
                   till
                   description
                   nextAssignedNumber
                   prefix
                   suffix
                   loyaltyProgram
                   accessController 
                   posType
                   manageCash
                   showopeningamount
                   showsalesamount
                   showexpectedamount
                   showdifferenceamount
                   shiftclose
                   shiftopen
                   eReceipt
                   printPreview
                   cashin
                   cashout
                   layAway
                   payNow
                   hardwareController{
                       imageUrl
                       printReceipt
                       weighingScale
                       payment
                       printBarcode
                   }
                   printTemplate{
                    cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2
                    xmlcode
                    xmlcode2
                    obController
                  }
                  tillCloseTemplate{
                       cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2 
                    xmlcode
                    xmlcode2
                    obController
                  }
                  kotPrintTemplate{
                       cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2 
                    xmlcode
                    xmlcode2
                  }
                  cancelKotPrintTemplate{
                       cwrPrinttemplateId
                    name
                    htmlcode
                    htmlcode2 
                    xmlcode
                    xmlcode2
                  }
                  
                }
                csBunit{
                  csBunitId
                  name
                  value
                  cwrSCustomerId
                  cwrCsDoctypeId
                  cwrPcatalogueId
                  cwrSpricelistId
                  pCatalogueSaleType{
                    cwrPcatalogueSaletypeId          
                    isPromoApplicable
                    cwrSaletypeId
                  }
                   currencies{
                    csCurrencyId
                    currSymbol
                    isoCode
                    prcPrecision
                    stdPrecision
                    cstgPrecision
                    symbolRightSide
                    denominations{
                        value
                        seqNo
                    }
                    conversions{
                        csCurrencyIDTo
                        currencyFromRate
                       currencyToRate
                       validfromDate
                       validtoDate
                       isoCode
                    }
                }
                  b2cCustomer{
                    cwrCustomerId
                    code
                    name
                    email
                    mobileNo
                    pincode
                    retlLoyaltyBalance
                    b2cRegisteredstoreId
                    iscredit
                    balancePoints
                    loyaltyLevel{
                    cwrLoyaltyLevelId
                    name
                    accumulationRate
                    redemptionRate
                  }
              
                sCustomer{
                sCustomerID
                customerCategory{
                  sCustomerCateforyId
                  value
                  name
                  description
              }
                }
                  }
                  paymentMethodList{
                    cWRPaymentMethodID
                    sequenceNo
                    finPaymentmethodId
                    finFinancialAccountId
                    finDayCloseAccountId
                    name
                    integratedPayment
                    isloyalty
                    paymentProvider
                    iscredit 
                    isGiftCard
                    isDefault
                    csCurrencyId
                    isoCode
                  }
                  mWarehouse{
                    mWarehouseID
                    name
                  }
                  customerAddress{
                    sCustomerAddressID
                      line1
                      line2
                      line3
                      fulladdress
                      phone
                      city
                      postalcode
                      csCountry{
                          csCountryID
                          name          
                      }
                      csRegion{
                          csRegionID
                          name
                      }
                  }
                  locations{
                      csBunitLocationId
                      fulladdress
                      phone
                      contactPerson
                  }
                   salesRep{
                      code
                      name
                      salesRepresentId
                  }
                  
                }
                
              }
              status
              message
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `${cleanToken}`,
        },
      });
      let tillData = resData.data.data.tillData;
      if (tillData.status === "200") {
        if (tillData !== null && tillData !== undefined) {
          const tillAccessIndex = tillData.tillAccess.findIndex((accessTill) => accessTill.cwrTill.till === nameTill);
          if (tillAccessIndex >= 0) {
            if (islocked) {
              history.push("/pos");
              localStorage.removeItem("locked");
            } else {
              Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `query{
                    getStoreOps(storeId:"${localTill.tillAccess.csBunit.csBunitId}",date:"${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"){
                       status
                    }
                }`,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${authHeaders.access_token}`,
                },
              }).then((result) => {
                if (result.data.data.getStoreOps === null) {
                  Axios({
                    url: serverUrl,
                    method: "POST",
                    data: {
                      query: `mutation {
                        upsertStoreOps(storeOps: {
                            cSBunitID: "${tillData.tillAccess[0].csBunit.csBunitId}"
                            date: "${moment(new Date()).format("YYYY-MM-DD")}"
                            status: "OP"
                            closedBy: null
                            closeTime: null
                            openedBy: "${tillData.tillAccess[0].csUserId}"
                            openTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                            remarks: null
                          }) {
                          status   
                          message
                        }
                      }
                      `,
                    },
                    headers: {
                      "Content-Type": "Application/json",
                      Authorization: `${authHeaders.access_token}`,
                    },
                  }).then((resStoreOps) => {
                    if (resStoreOps.data.data.upsertStoreOps.status === "200") {
                      history.push(`/login`);
                    } else {
                      message.error(resStoreOps.data.data.upsertStoreOps.message)
                      setTimeout(() => {
                        for (let key in localStorage) {
                          if (key !== "userData") {
                            localStorage.removeItem(key);
                          }
                        }
                        window.location.assign("/");
                      }, 3000);
                    }
                  })
                } else if (result.data.data.getStoreOps.status === "OP") {
                 
                  history.push(`/login`);
                } else {
                  setLoading(false);
                  Modal.confirm({
                    title: `This till has been closed "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"`,
                    icon: null,
                    cancelText: "",
                    okText: "Okay",
                    cancelButtonProps: { style: { display: 'none' } }, // Disable the cancel button
                    onOk() {
                      // Handle "Okay" button click
                      getTillData();
                    },
                  });
                }
              })
            }
        
          } else {
            setLoading({ status: false, message: "" });
            errorMessage("Login Failed", "User not configured for this Till", "user");
          }
        } else {
          setLoading({ status: false, message: "" });
          // errorMessage("Login Failed", "No Till Access Found");
        }
      } else {
        setLoading({ status: false, message: "" });
        errorMessage("Login Failed", "User not configured for this Till", "user");
      }
    } else {
      getTillData();
    }
  }, []);

  const getTillData = async () => {
    const authHeaders = getOAuthHeaders();
    if (authHeaders && authHeaders.access_token && (authHeaders.username || authHeaders.email)) {
      try {
        setLoading(true);
        const userData = JSON.parse(localStorage.getItem("userData"));
        const userid = userData?.user_id;

        const getTillMutation = {
          query: `query{
              getTills(name: null userId: "${userid}"){
                cwrTillID
                till
                searchKey
                status
                name
                cSUserId
                userName
                  tillHistory {
                      cwrTillRegHistoryId
                      regTimestamp
                      ram
                      processor
                      sessionid
                      macAddress
                      domain
                      localIpAddress
                      csBunitId
                  }
              }
          }`,
        };
        const headers = {
          "Content-Type": "application/json",
          Authorization: `${authHeaders.access_token}`,
        };
        const serverResponse = await Axios.post(serverUrl, getTillMutation, { headers: headers }, { async: true }, { crossDomain: true });

        const transformedData = serverResponse.data.data.getTills.reduce((result, item) => {
          const { name } = item;

          const foundIndex = result.findIndex((group) => group[name]);

          if (foundIndex === -1) {
            const newGroup = {
              [name]: [item],
            };
            result.push(newGroup);
          } else {
            result[foundIndex][name].push(item);
          }

          return result;
        }, []);
        setTills(transformedData);
        setLoading(false);
      } catch (error) {
        const { message } = JSON.parse(JSON.stringify(error));
        if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        } else {
          return Promise.reject(error);
        }
      }
    }
  };

  const onRegistration = async (search_key) => {
    const timeMark = timeStamp();
    const resData = await Axios({
      url: serverUrl,
      method: "POST",
      data: {
        query: `mutation {
        tillRegistration(
          till: {
            searchKey: "${search_key}"
            created: "${timeMark}"
            updated: "${timeMark}"
             tillAccess: { csUser: { username: "${Enterprise ? username : email}" } }
            tillHistory: {
              domain: null
              sessionid: null
              osVersion: null
              localIpAddress: null
              macAddress: null
              processor: null
              ram: null
              regTimestamp: "${timeMark}"
            }
          }
        ) {
          status
          message
          searchKey
          tillAccess {
            salesRep
            salesRepId
            csClientId
            csUser {
              csUserId
            }
            cwrTill {
              till
              searchKey
              description
              nextAssignedNumber
            }
            csBunit {
              csBunitId
              name
              cwrSCustomerId
              csCurrency {
                csCurrencyId
                currSymbol
                isoCode
                stdPrecision
                cstgPrecision
                prcPrecision
              }
            }
          }
          tillHistory {
            domain
            sessionid
            osVersion
            localIpAddress
            macAddress
            processor
            ram
            regTimestamp
            cwrTillRegHistoryId
          }
        }
      }`,
      },
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    });

    const tillData = resData.data.data.tillRegistration;
    if (tillData.status === "200") {
      await db.tillRegistrations.add(tillData);
      localStorage.setItem("documentSequence", tillData.tillAccess[0].cwrTill.nextAssignedNumber);
      setLoading({ status: false, message: "" });
      Axios({
        url: serverUrl,
        method: "POST",
        data: {
          query: `mutation {
                upsertPOSActivity(tillActivity: [
                  {
                    csBunitId: "${tillData.tillAccess[0].csBunit.csBunitId}"
                    csUserId: "${tillData.tillAccess[0].csUser.csUserId}"
                    tillRegistrationId: "${tillData.tillHistory[0].cwrTillRegHistoryId}"
                    type: "LI"
                    time: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                  }
                ]) {
                  status
                  message
                }
            }`,
        },
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      }).then(async (response) => {
        if (response.data.data.upsertPOSActivity.status === 200 || response.data.data.upsertPOSActivity.status === "200") {
          Axios({
            url: serverUrl,
            method: "POST",
            data: {
              query: `query{
                getStoreOps(storeId:"${tillData.tillAccess[0].csBunit.csBunitId}",date:"${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"){
                   status
                }
            }`,
            },
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${authHeaders.access_token}`,
            },
          }).then((result) => {
            if (result.data.data.getStoreOps === null) {
              Axios({
                url: serverUrl,
                method: "POST",
                data: {
                  query: `mutation {
                    upsertStoreOps(storeOps: {
                        cSBunitID: "${tillData.tillAccess[0].csBunit.csBunitId}"
                        date: "${moment(new Date()).format("YYYY-MM-DD")}"
                        status: "OP"
                        closedBy: null
                        closeTime: null
                        openedBy: "${tillData.tillAccess[0].csUser.csUserId}"
                        openTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                        remarks: null
                      }) {
                      status   
                      message
                    }
                  }
                  `,
                },
                headers: {
                  "Content-Type": "Application/json",
                  Authorization: `${authHeaders.access_token}`,
                },
              }).then((resStoreOps) => {
                if (resStoreOps.data.data.upsertStoreOps.status === "200") {
                  history.push(`/login`);
                } else {
                  message.error(resStoreOps.data.data.upsertStoreOps.message)
                  setTimeout(() => {
                    for (let key in localStorage) {
                      if (key !== "userData") {
                        localStorage.removeItem(key);
                      }
                    }
                    window.location.assign("/");
                  }, 3000);
                }
              })
            } else if (result.data.data.getStoreOps.status === "OP") {
              history.push(`/login`);
              // Axios({
              //   url: serverUrl,
              //   method: "POST",
              //   data: {
              //     query: `mutation {
              //       upsertStoreOps(storeOps: {
              //           cSBunitID: "${tillData.tillAccess[0].csBunit.csBunitId}"
              //           date: "${moment(new Date()).format("YYYY-MM-DD")}"
              //           status: "OP"
              //           closedBy: null
              //           closeTime: null
              //           openedBy: "${tillData.tillAccess[0].csUser.csUserId}"
              //           openTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
              //           remarks: null
              //         }) {
              //         status   
              //         message
              //       }
              //     }
              //     `,
              //   },
              //   headers: {
              //     "Content-Type": "Application/json",
              //     Authorization: `${authHeaders.access_token}`,
              //   },
              // }).then((resStoreOps) => {
              //   if (resStoreOps.data.data.upsertStoreOps.status === "200") {
              //     history.push(`/login`);
              //   } else {
              //     message.error(resStoreOps.data.data.upsertStoreOps.message)
              //     setTimeout(() => {
              //       for (let key in localStorage) {
              //         if (key !== "userData") {
              //           localStorage.removeItem(key);
              //         }
              //       }
              //       window.location.assign("/");
              //     }, 3000);
              //   }
              // })
            } else {
              setLoading(false);
              Modal.confirm({
                title: `This till has been closed "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"`,
                icon: null,
                cancelText: "",
                okText: "Okay",
                cancelButtonProps: { style: { display: 'none' } }, // Disable the cancel button
                onOk() {
                  // Handle "Okay" button click
                },
              });
            }
          })
        }
      });
    } else {
      setLoading({ status: false, message: "" });
      errorMessage(tillData.message);
      console.error(tillData);
    }
  };

  const onModalOkay = () => {
    setLoading(false);
    const keepKey = "userData"; // Replace with the key you want to keep
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      // Check if the current key is not the one you want to keep
      if (key !== keepKey) {
        localStorage.removeItem(key);
      }
    }
    // history.push("/")
  };

  const onUserModal = () => {
    const cookiesToDelete = document.cookie.split(";").map((cookie) => cookie.trim());
    const domainURL = process.env.REACT_APP_domain;
    for (let i = 0; i < cookiesToDelete.length; i++) {
      const cookie = cookiesToDelete[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      const domain = "domain=" + domainURL;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure=true; SameSite=Strict;" + domain;
    }

    // }
    localStorage.removeItem("tillSession");
    window.location.assign(`${SSOURL}sign-out/?redirect_uri=${redirectUrl}`);
  };

  const errorMessage = (modalTitle, modalContent, user) => {
    Modal.error({
      title: modalTitle,
      content: modalContent,
      onOk: user ? onUserModal : onModalOkay,
    });
  };

  const onTill = () => {
    window.location.assign(`${SSOURL}sign-in/?&redirect_uri=${redirectUrl}`);
  };
  const statusInfoModal = () => {
    Modal.confirm({
      title: "This Till is alredy In use",
      icon: null,
      cancelText: "",
      okText: "Okay",
      // onOk() {
      //   deleteCart(true);
      // },
    });
  };
  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={loading}>
      {authHeaders ? <Row>
        <Col style={{ backgroundColor: "#F5FAFF" }} span={8}>
          <div style={{ height: "100vh" }}>
            <Image preview={false} src={logo} style={{ paddingTop: "4.5vh", paddingLeft: "2.6em", width: "93%" }} />
            <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "1.8vw", paddingTop: "7vh", paddingBottom: "7vh", paddingLeft: "3em" }}>Welcome to Point of Sale</p>
            <Image preview={false} src={laptop} style={{ width: "26vw", marginLeft: "2em", height: "41vh" }} />
            <p style={{ fontFamily: "Inter", fontWeight: 600, color: "#667085", fontSize: "13px", paddingTop: "10vh", paddingLeft: "3em" }}>Point of Sale - Lite</p>
            <p style={{ fontFamily: "Inter", fontWeight: 600, color: "#667085", fontSize: "0.9vw", paddingTop: "0.5em", paddingLeft: "3em" }}>
              {" "}
              Centralize the operations of your store, process transactions, manage inventory, follow orders, create reports of the store’s activities and much more!
            </p>
          </div>
        </Col>
        <Col span={16}>
          <div style={{ display: "flex" }}>
            <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "20px", marginTop: "1.4em", paddingLeft: "1.4em" }}>Select a Till to login</p>
            <div style={{ marginLeft: "auto", marginRight: "2em" }}>
              <Button
                onClick={() => window.location.assign(`${appUrl}`)}
                style={{ backgroundColor: "#0C173A", marginTop: "2.5em", fontSize: "10px", borderRadius: "4px", color: "white", fontFamily: "Inter", fontWeight: 600 }}
              >
                Back to My Apps
              </Button>
            </div>
          </div>

          {authHeaders?.access_token &&
            (authHeaders?.username || authHeaders?.email) &&
            tills?.map((e, data) => (
              <Row style={{ marginLeft: "2em" }}>
                <Col span={24}>
                  <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "1vw", paddingTop: "0.5em", paddingLeft: "2.2em", color: "#585768", marginBottom: "1vh" }}>
                    {Object.keys(e)[0]}
                  </p>
                </Col>
                {e[Object.keys(e)[0]].map((res) => (
                  <Col span={5} key={res.cwrTillID}>
                    {" "}
                    <Card
                      id="step1"
                      bodyStyle={{ padding: "1vw" }}
                      style={{
                        height: "7.5em",
                        width: "11em",
                        borderRadius: "6px",
                        border: " 1px solid #EEEEEF",
                        boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.04)",
                        backgroundColor: "#F3F4F9",
                      }}
                      onClick={(event) => {
                        localStorage.setItem("tillValue", JSON.stringify({ search_key: res.searchKey, cwr_till_id: res.cwrTillID, name: res.till }));
                        onRegistration(res.searchKey);
                      }}
                    >
                      <div>
                        <Image preview={false} src={posImg} style={{ height: "1.5vw" }} />
                        <span style={{ marginLeft: "1em", top: "15px" }}>{res.till}</span>
                      </div>
                      <div style={{ paddingTop: "1em" }}>
                        <Image preview={false} src={res.status === "AVL" ? green : res.status === "INU" ? red : green} style={{ height: "0.9vw" }} />
                        <span style={{ marginLeft: "5px", fontSize: "1vw", fontWeight: 600 }}>
                          {res.status === "AVL" ? "Available" : res.status === "INU" ? "In Use" : "Not Registered"}
                        </span>
                      </div>
                      <p style={{ fontFamily: "Inter", fontWeight: 600, marginLeft: "1.3em", color: "#667085", fontSize: "0.9vw" }}>{res.userName}</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            ))}
          {!authHeaders.username && !authHeaders.email && (
            <Row style={{ marginLeft: "2em" }}>
              {/* <Col span={24}>
             <p style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "1vw", paddingTop: "0.5em", paddingLeft: "2.2em", color: "#585768", marginBottom: "1vh" }}>
               {Object.keys(e)[0]}
             </p>
           </Col> */}
              {/* {e[Object.keys(e)[0]].map((res) => ( */}
              <Col span={5} key={tillValue.cwrTillID}>
                {" "}
                <Card
                  id="step1"
                  bodyStyle={{ padding: "1vw" }}
                  style={{
                    height: "7.5em",
                    width: "11em",
                    borderRadius: "6px",
                    border: " 1px solid #EEEEEF",
                    boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.04)",
                    backgroundColor: "#F3F4F9",
                  }}
                  onClick={onTill}
                >
                  <div>
                    <Image preview={false} src={posImg} style={{ height: "1.5vw" }} />
                    <span style={{ marginLeft: "1em", top: "15px" }}>{tillValue?.name}</span>
                  </div>
                  <div style={{ paddingTop: "1em" }}>
                    {/* <Image preview={false} src={res.status === "AVL" ? green : res.status === "INU" ? red : green} style={{ height: "0.9vw" }} /> */}
                    <span style={{ marginLeft: "5px", fontSize: "1vw", fontWeight: 600 }}>
                      {/* {res.status === "AVL" ? "Available" : res.status === "INU" ? "In Use" : "Not Registered"} */}
                    </span>
                  </div>
                  <p style={{ fontFamily: "Inter", fontWeight: 600, marginLeft: "1.3em", color: "#667085", fontSize: "0.9vw" }}>{tillValue?.userName}</p>
                </Card>
              </Col>
              {/* ))} */}
            </Row>
          )}
        </Col>
      </Row> : <Row></Row>}
    </Spin>
  );
};
export default Till;
