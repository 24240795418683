import _ from "lodash";

export const BuyXPayYOfDifferent = async (product, pricingRule, setCart, cart, orderType, cartObj, cartRef, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let excludingFlag = true;
  let hasChanges = false;
  const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);

  if ((pricingRule.excludeProductCategories === "Y" && matchingCategoy) ||(pricingRule.excludeProductCategories !== "Y" && !matchingCategoy) ||(pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)) {
    excludingFlag = false;
  }

  const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);

  if ((pricingRule.excludeBrands === "Y" && matchingBrand) ||(pricingRule.excludeBrands !== "Y" && !matchingBrand) ||(pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)) {
    excludingFlag = false;
  }

  const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);

  if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||(pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||(pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)) {
    excludingFlag = false;
  }

  let pricingRuleFlag = true;
  if (pricingRule.mPricingXProducts.length > 0) {
    pricingRule.mPricingXProducts.some((op) => {
      cartObj.items.map((product) => {
        if (op.mProductId === product.productId && product.nextRule === "Y") {
          pricingRuleFlag = false;
        }
      });
    });
  } else {
    if (product.nextRule === "Y") {
      pricingRuleFlag = false;
    }
  }

  if (saleTypeValidation && excludingFlag && pricingRuleFlag && pricingRule.xQty >0 && pricingRule.yQty >0) {
    let addedToCart = [...cartObj.items]; // Create a copy of cartObj.items
    const offerProducts = pricingRule.mPricingXProducts;

    addedToCart.map((item) => {
      offerProducts.map((ele) => {
        if (ele.mProductId === item.productId) {
          const sp = parseFloat(item.realPrice * item.weight);
          const mrp = sp;
          const tax = mrp - mrp / (1 + item.taxRate / 100);
          item.taxAmount = tax;
          item.nettotal = mrp;
          item.discountName = "";
          item.mPricingruleId = null;
          item.discount = 0;
          item.discountType = "";
          item.freeItem = "N";
        }
      });
    });

    let matchedItems = [];
    let TotalQty = 0;

    const updatedAddedToCart = addedToCart.filter((ele) => {
      if(ele.discount <= 0){
        delete ele.priority;
      }
      let shouldDelete = false;
      offerProducts.forEach((condProduct) => {
        if (ele.productId === condProduct.mProductId && !ele.discountType && !ele.mPricingruleId && (ele?.priority ? ele?.priority < pricingRule.priority : true)) {
          matchedItems.push(ele);
          TotalQty += ele.weight;
          shouldDelete = true;
        }
      });
    
      return !shouldDelete;
    });
    

    const sortedItems = [...matchedItems].sort((a, b) => a.realPrice - b.realPrice);

    let remainingDiscountQty = Math.floor(TotalQty / pricingRule.xQty);
    let totalDiscountApplied = 0;
    sortedItems.forEach((item) => {
      if (remainingDiscountQty === 0) return;
      const discountQty = Math.min(item.weight, remainingDiscountQty);

      if (totalDiscountApplied + discountQty <= remainingDiscountQty) {
        // Apply full discount to the item
        let discountProductQty = (discountQty);
        const discountAmt = item.realPrice * discountProductQty;
        const sp = parseFloat(item.realPrice * item.weight) - discountAmt;
        const mrp = item.realPrice * item.weight;
        const tax = mrp - mrp / (1 + item.taxRate / 100);
        item.taxAmount = tax;
        item.priority = pricingRule.priority;
        item.nettotal = mrp - discountAmt;
        item.discount = discountAmt;
        item.discountName = pricingRule.printedName;
        item.mPricingruleId = pricingRule.mPricingrulesId;
        remainingDiscountQty -= discountQty;
        totalDiscountApplied += discountQty;
        hasChanges = true;
      } else {
        // Apply partial discount to the item
        const remainingDiscount = remainingDiscountQty;
        let discountProductQty = (remainingDiscount);
        const discountAmt = item.realPrice * discountProductQty;
        const sp = parseFloat(item.realPrice * item.weight) - discountAmt;
        const mrp = sp;
        const tax = mrp - mrp / (1 + item.taxRate / 100);
        item.taxAmount = tax;
        item.nettotal = mrp;
        item.discount = discountAmt;
        item.discountName = pricingRule.printedName;
        item.priority = pricingRule.priority;
        item.mPricingruleId = pricingRule.mPricingrulesId;
        remainingDiscountQty -= remainingDiscount;
        totalDiscountApplied += remainingDiscount;
        hasChanges = true;
      }
    });

    addedToCart = [...updatedAddedToCart,...sortedItems];
    if(hasChanges){
      let totalTax = 0;
      let totalPrice = 0;
      let totalItemsQty = 0;
      let totalDiscounts = 0;
      for (let i = 0; i < addedToCart.length; i += 1) {
        totalPrice += addedToCart[i].nettotal;
        totalItemsQty += addedToCart[i].weight;
        totalTax += addedToCart[i].taxAmount;
        totalDiscounts += addedToCart[i].discount;
        addedToCart[i].key = i;
      }
  
      const roundOffValue = Math.round(totalPrice);
      const totalRoundOff = totalPrice - roundOffValue;
      totalPrice = roundOffValue;
      let couponsData = cart.couponInput?.length > 0 ? [...cart.couponInput] : [];
      if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null) {
        couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: mPricingRulesId });
      }
      let finalCartObj = {
        ...cartObj,
        couponInput: couponsData,
        items: [...addedToCart],
        total: totalPrice,
        tax: totalTax,
        discount: totalDiscounts,
        totalQty: totalItemsQty,
        roundOff: totalRoundOff,
      };
      setCart(finalCartObj);
      localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
      return finalCartObj;
    }
  }
};
