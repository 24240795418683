import _ from "lodash";

export const BuyXPayYOfSame = async (product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts) => {
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let hasChanges = false;
  if (saleTypeValidation && pricingRule.xQty >0 && pricingRule.yQty >0) {
    let addedToCart = [...cartObj.items]; // Create a copy of cartObj.items
    const offerProducts = pricingRule.mPricingXProducts;

    addedToCart.forEach((ele) => {
      let excludingFlag = true;
      const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === ele.mProductCategoryId);

      if ((pricingRule.excludeProductCategories === "Y" && matchingCategoy) ||(pricingRule.excludeProductCategories !== "Y" && !matchingCategoy) ||(pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)) {
        excludingFlag = false;
      }

      const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === ele.productBrandId);

      if ((pricingRule.excludeBrands === "Y" && matchingBrand) ||(pricingRule.excludeBrands !== "Y" && !matchingBrand) ||(pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)) {
        excludingFlag = false;
      }

      const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === ele.productId);

      if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||(pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||(pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)) {
        excludingFlag = false;
      }

      if(ele.discount <= 0){
        delete ele.priority;
      }
      
      offerProducts.forEach((condProduct) => {
          if (ele.productId === condProduct.mProductId && ele.weight >= pricingRule.xQty && (ele?.priority ? ele?.priority < pricingRule.priority : true) && excludingFlag) {
              // Create a new item with remaining quantity
              let modValue = Math.floor(ele.weight / pricingRule.xQty)
              let discountProductQty = (pricingRule.xQty - pricingRule.yQty) * modValue;
              const discountAmt = ele.realPrice * discountProductQty;
              const sp = parseFloat(ele.realPrice * ele.weight) - discountAmt;
              const mrp = sp;
              const tax = mrp - mrp / (1 + ele.taxRate / 100);
              ele.taxAmount = tax;
              ele.nettotal = mrp;
              ele.discount = discountAmt;
              ele.priority = pricingRule.priority;
              ele.discountName = pricingRule.printedName;
              ele.mPricingruleId = pricingRule.mPricingrulesId;
              hasChanges = true;
          }
      });
  });
    if(hasChanges){
      let totalTax = 0;
      let totalPrice = 0;
      let totalItemsQty = 0;
      let totalDiscounts = 0;
      for (let i = 0; i < addedToCart.length; i += 1) {
        totalPrice += addedToCart[i].nettotal;
        totalItemsQty += addedToCart[i].weight;
        totalTax += addedToCart[i].taxAmount;
        totalDiscounts += addedToCart[i].discount;
        addedToCart[i].key = i;
      }
  
      const roundOffValue = Math.round(totalPrice);
      const totalRoundOff = totalPrice - roundOffValue;
      totalPrice = roundOffValue;
      let couponsData = cart.couponInput?.length > 0 ? [...cart.couponInput] : [];
      if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null) {
        couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: mPricingRulesId });
      }
      let finalCartObj = {
        ...cartObj,
        couponInput: couponsData,
        items: [...addedToCart],
        total: totalPrice,
        tax: totalTax,
        discount: totalDiscounts,
        totalQty: totalItemsQty,
        roundOff: totalRoundOff,
      };
      setCart(finalCartObj);
      localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
      return finalCartObj;
    }
  }

}