import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Card, Input, Button, message } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import { serverUrl } from "../../constants/config";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { v4 as uuidv4 } from "uuid";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/icon.png";
import Axios from "axios";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import _ from "lodash";
import { getOAuthHeaders } from "../../constants/oAuthValidation";

const OpenTillComponent = () => {
  const history = useHistory();
  const [openTillForm] = Form.useForm();
  const serverUrl = process.env.REACT_APP_serverUrl;
  const dynamicHeight = 0.5625;
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  const [inputValue, setInputValue] = useState({});
  const [inputAmount, setInputAmount] = useState();
  const [noteValue, setNoteValue] = useState("");
  const authHeaders = getOAuthHeaders();
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const amountRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const [currencyType, setCurrencyType] = useState({
    currSymbolLeft: "₹",
    currSymbolRight: "",
    stdPrecision: 2,
  });

  useEffect(() => {
    amountRef.current.focus();
    const tillSession = localStorage.getItem("tillSession");
    if (tillSession) {
      if (JSON.parse(tillSession).tillStatus === "open") history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
    } else {
      db.tillRegistrations.toArray().then((reg) => {
        if (reg.length > 0) {
          const curList = reg[0].tillAccess[0].csBunit.csCurrency;
          const csCurrencyId = curList.csCurrencyId;
          localStorage.setItem("csCurrencyId", csCurrencyId);
          setCurrencyType({
            currSymbolLeft: curList.symbolrightside ? "" : curList.currSymbol,
            currSymbolRight: curList.symbolrightside ? curList.currSymbol : "",
            stdPrecision: curList.stdPrecision,
          });
        }
      });
    }
  }, []);

  const [denominations, setDenominations] = useState([]);

  useEffect(() => {
    const modifiedCurrenciesList = currenciesList[0].denominations?.map((denomItem, denomIndex) => ({
      ...denomItem,
      count: 0,
      amount: 0,
    }));
    setDenominations([...modifiedCurrenciesList]);
  }, []);

  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(() => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);

  const onFieldInput = (e) => {
    let amountValue = posConfig.showDayOpeningAmount === "Y" ? inputAmount : 0;
    const denominationValue = parseInt(amountValue);
    const denominationAmount = denominationValue;
    // const di = denominations.findIndex((d) => d.value === denominationName);
    if(denominations && denominations.length > 0){
      denominations[0].count = 1;
      denominations[0].amount = isNaN(denominationAmount) ? 0 : denominationAmount;
    }
    // let total = 0;
    // denominations.forEach((denom) => {
    //   total += denom.amount;
    // });
    setTotalAmount(inputAmount);
    setDenominations([...denominations]);
    setInputAmount(0);
    excuteOpenTill(amountValue, denominations);
  };

  const confirmBtnRef = useRef(null);

  const excuteOpenTill = async(inputAmount, denominationsData) => {
    if (authHeaders && authHeaders.access_token) {
      const tillSessionId = uuidv4().replace(/-/g, "").toUpperCase();
      const openTillData = {
        tillSessionId: tillSessionId,
        tillStatus: "open",
        openingTime: timeStamp(),
        closingTime: null,
        openingCashDenominations: denominationsData,
        closingCashDenominations: null,
        openingCash: parseFloat(inputAmount) >= 0 ? inputAmount : 0,
        closingCash: null,
        totalOpeningAmount: parseFloat(inputAmount) >= 0 ? inputAmount : 0,
        totalClosingExpectedAmount: null,
        totalClosingTransactions: null,
        totalClosingAmount: null,
        note: noteValue,
      };
      localStorage.setItem("tillSession", JSON.stringify(openTillData));
      if (
        db.logConfiguration.toArray().then((fetched) => {
          fetched.map((item) => {
            item.dayOpening.filter((item) => item.log === "Y").length > 0;
          });
        })
      ) {
        const timeMark = timeStamp();
        const currentDate = new Date().toLocaleDateString("zh-Hans-CN");
        const dayOpeningArray = [];
        dayOpeningArray.push(`{
          type: "DON",
          action: "LOG",
          description: "${timeMark}",
          date: "${currentDate}",
          time: "${timeMark}",
          orderNo: "",
          remarks: "",
        }`);
        Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `mutation {
            upsertPOSLog(order: {
                tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                userId: "${tillData.tillAccess.csUserId}" 
                bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                lines: [${dayOpeningArray}]
              }) {
              status   
              message
            }
          }`,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${authHeaders.access_token}`,
          },
        });
      }
      let index = tillData.tillAccess.csBunit.paymentMethodList.findIndex((payment) => payment.name.toLowerCase() === "cash")
      const paramsInput = {
        query: `mutation{
          upsertTill(tillInfo:{
            cwrTillID:"${tillData.tillAccess.cwrTill.cwrTillID}"
            csbunitID:"${tillData.tillAccess.csBunit.csBunitId}"
            csclientID:"${tillData.tillAccess.csClientId}"
            created:"${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
            createdby:"${tillData.tillAccess.csUserId}"
            updated:"${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
            updatedby:"${tillData.tillAccess.csUserId}"
            tillCash:[{
              cwrTillCashId: "${uuidv4().replace(/-/g, "").toUpperCase()}"
              date: "${moment(new Date()).format("YYYY-MM-DD")}"
              finPaymentmethodId: "${tillData.tillAccess.csBunit.paymentMethodList[index].finPaymentmethodId}"
              finFinancialAccount2Id: null
              opening: ${parseFloat(inputAmount) >= 0 ? inputAmount : 0}
              notes: ${noteValue ? noteValue : null}
              sales: 0
              netsales: 0
              cashin: 0
              cashout: 0
              retainAmt: 0
              closing: 0
              returns: 0
              iscash: 1
              isclose: 0
              pettyCashIn: 0,
              pettyCashOut: 0,
              cashInOut: [],
              cashEvents: []
            }]
          })
          {    
            status
            message
            cwrTillID
                  tillCash{
              
              cwrTillCashId
              date
              finPaymentmethodId
              opening
              sales
              netsales
              cashin
              cashout
              retainAmt
              closing
              returns
              iscash
              notes
              isclose
              storeDailyOpsTillid
              cashEvents{
                cwrCashEventsID
                amount
                expected
                diff
                transactionCount
                type
                description
                cashEventDetails{
                  cwrCashEventdetailsID
                  count
                  amount
                  denomination
                }
              }
            }
          }
        }`,
      };

     await Axios({
        url: serverUrl,
        method: "POST",
        data: paramsInput,
        headers: {
          "Content-Type": "Application/json",
          Authorization: `${authHeaders.access_token}`,
        },
      }).then((response) => {
        if(response.data.data.upsertTill.status === "200"){
          localStorage.setItem("tillCash",JSON.stringify(response.data.data.upsertTill.tillCash));
          localStorage.setItem("storeDailyOpsTillid",response.data.data.upsertTill.tillCash[0].storeDailyOpsTillid);
          history.push(tillLayout === 2 && tillaccess?.dineIn === "Y" ? "/table-management" : "/pos");
        }else {
          message.error(response.data.data.upsertTill.message);
          localStorage.removeItem("tillSession");
        }
      })
    }
  };

  const focusFirstElement = () => {
    setTimeout(() => {
      cancelBtnRef.current.focus();
    }, 100);
  };

  const resetFocus = (index) => {
    if (index + 1 === denominations.length) {
      focusFirstElement();
    }
  };

  const handleHome = () => {
    history.push("/dashboard");
  };
  let reconstructedObject;
  // const rawData = tillData?.tillAccess?.tillAccessMeta[0]?.value;
  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;

  if (rawtillAccessMeta) {
    const rawDataArray = tillData?.tillAccess?.tillAccessMeta;

    // Find the object with the key "Open Till"
    const openTillObject = rawDataArray.find((item) => item.key === "Open Till");

    // Access the value property of the found object
    const openTillValue = openTillObject?.value;
    // const rawData = tillData?.tillAccess?.tillAccessMeta?.[0]?.value;

    if (openTillValue !== undefined) {
      // The rest of your code that depends on openTillValue
      // Clean up the string (remove extra characters)
      const cleanedData = openTillValue.replace(/[{}"]/g, "");

      // Split the string into key-value pairs
      const keyValuePairs = cleanedData.split(",");

      // Create an object from key-value pairs
      reconstructedObject = {};
      keyValuePairs.forEach((pair) => {
        // Check if the pair contains a colon
        if (pair.includes(":")) {
          const [key, value] = pair.split(":");

          // Check if both key and value are not undefined before trimming
          if (key !== undefined && value !== undefined) {
            reconstructedObject[key.trim()] = value.trim();
          }
        }
      });
    } else {
      // Handle the case where openTillValue is undefined
      console.error("openTillValue is undefined");
    }
  }

  return (
    <div style={data.openTill.mainContainer}>
      <Row align="center" justify="center" style={{ paddingTop: "2vh" }}>
        <Col span={12}>
          <Row style={{ height: "6vh", padding: "1% 0 24.5% 0%" }}>
            <Col span={18} offset={1} style={{ display: "flex", alignItems: "center" }}>
              <img src={POSLogo} style={data.openTill.header.menuIcon} alt="" />
              <label style={{ fontSize: "2vw", color: "#000000", marginLeft: "5%", letterSpacing: "0.36px", opacity: 1, fontWeight: "600", textAlign: "center" }}>Open Till</label>
            </Col>
          </Row>
          <Row style={{ paddingTop: "1vh" }}>
            <Col offset={4} span={18} style={{ display: "flex", flexDirection: "column" }} id="step1">
              <Form form={openTillForm} name="oprnTillForm">
                <Form.Item style={{ width: "100%" }} name="amount" id="step1">
                  <p style={{ marginBottom: "0%", fontSize: "2.5vh", fontFamily: "Inter", fontWeight: "600", letterSpacing: "0px", color: "#000000" }}>Amount</p>
                  <Input
                    placeholder={
                      currenciesList[0].symbolRightSide === "N"
                        ? `${currenciesList[0].currSymbol}0`
                        : currenciesList[0].symbolRightSide === "Y"
                        ? `0${currenciesList[0].currSymbol}`
                        : ""
                    }
                    onChange={(e) => {
                      setInputValue(e.target);
                      setInputAmount(e.target.value);
                    }}
                    ref={amountRef}
                    value={inputAmount}
                    // readOnly={true}
                    className="transactionAmtInput"
                    style={{ marginBottom: "3vh", height: "8.5vh" }}
                  />
                </Form.Item>
                <Form.Item style={{ width: "100%" }} name="note">
                  <p style={{ marginBottom: "0%", fontSize: "2.5vh", fontFamily: "Inter", fontWeight: "600", letterSpacing: "0px", color: "#000000" }}>Note</p>
                  <Input
                    placeholder="Enter note"
                    // value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                    // readOnly={true}
                    onChange={(e) => setNoteValue(e.target.value)}
                    className="transactionAmtInput"
                    style={{ marginBottom: "1.7vh", height: "8.5vh" }}
                  />
                </Form.Item>
                <Button
                  id="step3"
                  htmlType="submit"
                  onClick={onFieldInput}
                  style={{ width: "100%", height: "8.5vh", backgroundColor: "#A4CD38", color: "#fff", marginTop: "5vh", fontSize: "3vh", fontWeight: "600" }}
                >
                  Open Till
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col span={12} style={{ paddingRight: "5.5%", paddingTop: "1.7%", paddingLeft: "1.7%" }}>
          <div id="step2" style={{ backgroundColor: "#F3F4F9", height: "88vh", borderRadius: "5px" }}>
            <p style={{ fontSize: "2.5vh", fontWeight: "600", paddingTop: "2.5vh", paddingLeft: "2vw" }}>Last Closure Details</p>
            <Row>
              <Col span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Outlet</p>{" "}
              </Col>
              <Col span={11}>
                <p style={data.openTill.transactionsAmtRight1}>{userData.bunit_iden}</p>
              </Col>
              <Col span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Register</p>{" "}
              </Col>
              <Col span={11}>
                <p style={data.openTill.transactionsAmtRight1}>{tillData.tillAccess.cwrTill.till}</p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Opening Amount</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                  {inputAmount?.length <= 0 ? 0 : inputAmount}
                  {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Cash in</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Cash Out</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Petty Cash in</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Petty Cash Out</p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.ExpectedCash === "Y" ? "block" : "none") : "block" }} span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Expected Cash </p>{" "}
              </Col>
              <Col style={{ display: reconstructedObject ? (reconstructedObject.ExpectedCash === "Y" ? "block" : "none") : "block" }} span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
              {/* <Col span={11}>
                <p style={data.openTill.transactionsAmtRight1}>{currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</p>
                  </Col> */}
              <Col span={11} offset={1}>
                <p style={data.openTill.transactionsAmt1}>Actual Cash</p>{" "}
              </Col>
              <Col span={11}>
                <p style={data.openTill.transactionsAmtRight1}>
                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}0{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
        <Col span={2}>
          <img src={MenuIcon} style={data.openTill.header.menuIcon} alt="" />
        </Col>
        <Col span={10}>
          <label style={data.openTill.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={8}>
          <label style={data.openTill.header.timeFmt}>OPEN TILL</label>
        </Col>
        <Col span={4} style={data.openTill.header.right}>
          <img src={POSLogo} style={data.openTill.header.posLogoIcon} alt="" onClick={handleHome}/>
        </Col>
      </Row>
      <div style={data.openTill.mainCardContainer}>
        <Row gutter={10}>
          <Col span={18}>
            <Card bodyStyle={{ padding: 10 }} style={{ borderRadius: "3px" }}>
              <Card key="z" style={data.openTill.mainCardFirst} bodyStyle={{ padding: 0 }}>
                <Row style={{ padding: 10 }}>
                  <Scrollbars autoHide className="tillScroll">
                  <Row style={{ marginBottom: "2%" ,height:"70vh",justifyContent:"center",display:"flex",alignItems:"center"}}>
                        <Col span={8} style={data.openTill.colAlign}>
                        </Col>
                        <Col span={8} style={data.openTill.colAlign}>
                          <div style={data.openTill.calInputDiv}>
                            <Form.Item style={{width:"100%"}}>
                            <div style={{textAlign:"left",paddingBottom:"10px"}}>Enter Amount</div>
                              <Input tabIndex={0} style={{"padding": "2px","borderRadius": "5px","border": "1px solid #ebeded","boxShadow": "none",height:"6vh"}} onChange={(e)=>{setInputValue(e.target);setInputAmount(e.target.value)}} value={inputAmount}/>
                            </Form.Item>
                          </div>
                          <div  style={data.openTill.calInputDiv}>
                          <Button tabIndex={1} className="focusButtonsTill" ref={confirmBtnRef} style={{"width": "100%","marginLeft": "2%","backgroundColor": "#0C173A","color": "#ffffff","fontSize": "100%","border": "none",}} onClick={onFieldInput} >
                            Add Amount
                          </Button>
                          </div>
                        </Col>
                        <Col span={8} style={data.openTill.colAlign}>
                        </Col>
                  </Row>
                    <span tabIndex={0}></span>
                  </Scrollbars>
                </Row>
              </Card>
            </Card>
          </Col>
          <Col span={6}>
            <Card bodyStyle={{ padding: 10 }} style={data.openTill.opentillRightCard}>
              <Link to="/dashboard">
                <Button tabIndex={1} className="focusButtonsTill" ref={cancelBtnRef} style={data.openTill.btnCancel}>
                  Cancel
                </Button>
              </Link>
              <Button tabIndex={1} className="focusButtonsTill" ref={confirmBtnRef} style={data.openTill.btnConfirm} onClick={excuteOpenTill}>
                Confirm
              </Button>
              <p style={data.openTill.emptyP} />
              <p style={data.openTill.emptyP}>Opened On</p>
              <div style={{ marginBottom: "0.5rem" }}>
                <span style={data.openTill.currentDate}>{currentDate}</span>
                <span style={data.openTill.displayClock}>{displayClock}</span>
              </div>
              <Form>
                <Form.Item style={{ marginBottom: "0.5rem" }}>
                  <p style={data.openTill.transactionsAmt}>Total Amount</p>
                  <Input
                    tabIndex={-1}
                    value={`${currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} ${parseFloat(totalAmount).toFixed(currencyType.stdPrecision)} ${currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}`}
                    readOnly={true}
                    className="transactionAmtInput"
                  />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div> */}
    </div>
  );
};

export default OpenTillComponent;
